import changeFirstName from './changeFirstName'
import changeLastName from './changeLastName'
import changeAddress from './changeAddress'
import changeCity from './changeCity'
import changePostalCode from './changePostalCode'
import changeEmail from './changeEmail'
import changeProvince from './changeProvince'
import changeInitialTimeRemaining from './changeInitialTimeRemaining'
import changeID from './changeID'
import changeStartTime from './changeStartTime'
import changeQuestions from './changeQuestions'
import changeQuestionAnswers from './changeQuestionAnswers'
import changeUserResponses from './changeUserResponses'
import changeTimedUserResponses from './changeTimedUserResponses'
import changeTestTimes from './changeTestTimes'
import changeCaseStudyTimes from './changeCaseStudyTimes'
import changePhoneNumber from './changePhoneNumber'
import changeVoiceRecord from './changeVoiceRecord'
import changeInfoButtonResponse from './changeInfoButtonResponse'
import { combineReducers } from 'redux'

const changeStartedCS = (state = false, action) => {
	switch (action.type) {
		case 'CHANGESTARTEDCS':
			return action.payload
		default:
			return state
	}
}

const changeCSQuestionIndex = (state = 0, action) => {
	switch (action.type) {
		case 'CHANGECSQUESTIONINDEX':
			return action.payload
		default:
			return state
	}
}

const changeTestID = (state = 0, action) => {
	switch (action.type) {
		case 'CHANGETESTID':
			return action.payload
		default:
			return state
	}
}

const changeAuthenticated = (state = false, action) => {
	switch (action.type) {
		case 'CHANGEAUTHENTICATED':
			return action.payload
		default:
			return state
	}
}

const changeAccessCode = (state = '', action) => {
	switch (action.type) {
		case 'CHANGEACCESSCODE':
			return action.payload
		default:
			return state
	}
}

const changeLogo = (state = '', action) => {
	switch (action.type) {
		case 'CHANGELOGO':
			return action.payload
		default:
			return state
	}
}

const changeCaseStudyFiles = (state = [], action) => {
	switch (action.type) {
		case 'CHANGECASESTUDYFILES':
			return action.payload
		default:
			return state
	}
}

const changeScoringTags = (state = [], action) => {
	switch (action.type) {
		case 'CHANGESCORINGTAGS':
			return action.payload
		default:
			return state
	}
}

const changeScoringTagResps = (state = [], action) => {
	switch (action.type) {
		case 'CHANGESCORINGTAGRESPS':
			return action.payload
		default:
			return state
	}
}

const changePageIndex = (state = 0, action) => {
	switch (action.type) {
		case 'CHANGEPAGEINDEX':
			return action.payload
		default:
			return state
	}
}

const changeSelfIdentificationQuestions = (state = [], action) => {
	switch (action.type) {
		case 'CHANGESELFIDENTIFICATIONQUESTIONS':
			return action.payload
		default:
			return state
	}
}

const changePersonalAccessCode = (state = '', action) => {
	switch (action.type) {
		case 'CHANGEPERSONALACCESSCODE':
			return action.payload
		default:
			return state
	}
}

const allReducers = combineReducers({
	pageIndex: changePageIndex,
	firstName: changeFirstName,
	lastName: changeLastName,
	address: changeAddress,
	city: changeCity,
	postalCode: changePostalCode,
	email: changeEmail,
	province: changeProvince,
	phoneNumber: changePhoneNumber,
	id: changeID,
	initialTimeRemaining: changeInitialTimeRemaining,
	startTime: changeStartTime,
	questions: changeQuestions,
	userResponses: changeUserResponses,
	questionAnswers: changeQuestionAnswers,
	timedUserResponses: changeTimedUserResponses,
	testTimes: changeTestTimes,
	caseStudyTimes: changeCaseStudyTimes,
	voiceRecord: changeVoiceRecord,
	infoButtonResponse: changeInfoButtonResponse,
	startedCS: changeStartedCS,
	cSQuestionIndex: changeCSQuestionIndex,
	testID: changeTestID,
	authenticated: changeAuthenticated,
	accessCode: changeAccessCode,
	caseStudyFiles: changeCaseStudyFiles,
	scoringTags: changeScoringTags,
	scoringTagResps: changeScoringTagResps,
	logo: changeLogo,
	selfIdentificationQuestions: changeSelfIdentificationQuestions,
	personalAccessCode: changePersonalAccessCode,
})

export default allReducers
