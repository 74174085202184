const changeTimedUserResponses = (state = [], action) => {
	switch (action.type) {
		case 'CHANGE_CASE_STUDY_TIMES':
			return action.payload

		case 'START_CASE_STUDY_TIMER':
			const { startTime } = action.payload
			return [...state, { startTime, endTime: startTime }]

		case 'END_CASE_STUDY_TIMER':
			const { endTime } = action.payload
			const newCaseStudyTimes = [...state]

			if (newCaseStudyTimes.length === 0) {
				console.error('Error: Tried to end a case study time that was not initiated.')
				return state
			}
			newCaseStudyTimes[newCaseStudyTimes.length - 1].endTime = endTime
			return newCaseStudyTimes

		default:
			return state
	}
}

export default changeTimedUserResponses
