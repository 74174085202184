import React, { useState } from 'react'
import NavButton from '../components/Button/NavButton'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import PositionInfoButton from '../components/PositionInfoButton'
import PositionDetailsPopup from '../components/PositionDetailsPopup'
import HelpButton from '../components/HelpButton'
import { useEffect } from 'react'

import './Pages.css'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'
import PageTitle from '../components/Heading/PageTitle'

function LastQuestion(props) {
	const [showInfo, setShowInfo] = useState(false)
	const logo = useSelector((state) => state.logo)
	const questions = useSelector((state) => state.questions)
	const [time, setTime] = useState(15)

	useEffect(() => {
		for (const question of questions) {
			if (question.section === 'LastQuestion') {
				setTime(parseInt(question.helperText) / 60 / 1000)
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<PageContainer>
			<CardContainer style={{ paddingTop: 0 }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: 'calc(100% + 25px)',
						minHeight: 75,
					}}
				>
					<img src={logo} alt='' style={{ maxHeight: 100, maxWidth: 200, display: 'flex', justifyContent: 'start' }} />
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<PositionInfoButton setTrigger={setShowInfo} trigger={showInfo} />
						<HelpButton />
					</div>
				</div>

				<div style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
					<PageTitle style={{ marginBottom: 0 }}>One last question before you go!</PageTitle>

					<Typography style={{ fontSize: 16, textAlign: 'start' }}>
						You're nearly done but we have one last question before you leave. As soon as you hit the next button below, the
						timer will start and you will have
						<b> {time} minutes </b>
						to review the question and provide your answer.
					</Typography>

					<Typography style={{ fontSize: 16, fontWeight: 'bold', textAlign: 'start' }}>Are you ready?</Typography>
				</div>

				<div className='NavigationContainer'>
					<NavButton text='Back' onClick={() => props.prevPage()} />
					<NavButton text='Next' onClick={() => props.nextPage()} />
				</div>
			</CardContainer>
			<div style={{ display: 'flex', justifyContent: 'end' }}>
				{showInfo ? <PositionDetailsPopup setTrigger={setShowInfo} /> : <div />}
			</div>
		</PageContainer>
	)
}

export default LastQuestion
