import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, LinearProgress, Typography } from '@mui/material'

import MicIcon from '@mui/icons-material/Mic'
import Play from '@mui/icons-material/PlayCircle'
import Delete from '@mui/icons-material/Delete'
import Pause from '@mui/icons-material/PauseCircle'
import Timer from '../components/Timer'
import Stop from '@mui/icons-material/Stop'
import MicRecorder from 'mic-recorder-to-mp3'
import ReplayIcon from '@mui/icons-material/Replay'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'

import { convertToTimeString, getRelScoringTags } from '../functions/HelperFunctions'
import { changeVoiceRecord, changeUserResponses } from '../actions'
import { addScoringTagResponses } from '../functions/ScoringTagFunctions'

// Adds the scoring tag response with negative score if a user cannot use their microphone
// Adds the user response with negative score if a user cannot use their microphone
function addVoiceUseMicResponse(tags, tagResponses, userResponses, question, userID, dispatch) {
	let id = ''

	// Check to make sure that the scoring tag exists
	for (let tag of tags) {
		if (tag.tag === 'VoiceUseMicrophone') {
			id = tag.id
			break
		}
	}

	// No tag found
	if (id === '') return

	// Tag exists, so add a scoring tag response
	const newResponse = { scoringTagID: id, score: -1 }
	addScoringTagResponses(tagResponses, [newResponse], dispatch)

	let found = -1

	// Search for an existing user response
	for (let i = 0; i < userResponses.length; i++) {
		if (userResponses[i].questionID === question.id) {
			found = i
			break
		}
	}

	if (found === -1) {
		// Did not find the response. Create a new user response

		const newResponse = {
			questionID: question.id,
			userID: userID,
			response: '',
			score: -1,
		}

		dispatch(changeUserResponses([...userResponses, newResponse]))
	} else {
		// Found the response. Update score to -1
		userResponses[found].score = -1
		dispatch(changeUserResponses([...userResponses]))
	}
}

function scoreVoiceDuration(duration) {
	let diff = Math.abs(duration - 120)

	if (diff <= 10) {
		return 5
	} else if (diff <= 15) {
		return 3
	} else if (diff <= 20) {
		return 1
	} else {
		return 0
	}
}

function VoiceQuestion(props) {
	const [recording, setRecording] = useState(false)
	const [startTime, setStartTime] = useState(0)
	const [recorder] = useState(
		new MicRecorder({
			bitRate: 128,
		})
	)
	const [progress, setProgress] = useState(0)
	const [volume, setVolume] = useState(0.5)
	const [playing, setPlaying] = useState(false)
	const [player, setPlayer] = useState()
	const [recordingExists, setRecordingExists] = useState(false)
	const [currPlayTime, setCurrPlayTime] = useState('00:00 / 00:00')

	const dispatch = useDispatch()
	const userResponses = useSelector((state) => state.userResponses)
	const userID = useSelector((state) => state.id)
	const mp3file = useSelector((state) => state.voiceRecord)

	const tags = getRelScoringTags(
		useSelector((state) => state.scoringTags),
		props.question
	)
	const tagResponses = useSelector((state) => state.scoringTagResps)

	const playerRef = useRef()
	playerRef.current = player

	let xOffset

	useEffect(() => {
		if (mp3file !== '') {
			setPlayer(new Audio(mp3file))
			setRecordingExists(true)

			// If there is no recording, add/update scoring tag response for "VoiceUseMicrophone" scoring tag and a user response if the tag exists
		} else {
			addVoiceUseMicResponse(tags, tagResponses, userResponses, props.question, userID, dispatch)
		}

		return () => {
			// Pauses audio if playing while you leave page
			if (playerRef.current && playerRef.current.currentTime > 0) {
				playerRef.current.pause()
			}

			// Stops recording and uploads voice if you leave page without stopping recording
			if (recorder.activeStream && recorder.activeStream.active) {
				onStopRecording()
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const interval = setInterval(() => {
			if (recording && new Date().getTime() - startTime > 180000) {
				// Stops the recording if it gets to 3 min
				onStopRecording()
			}

			if (playing) {
				setProgress((player.currentTime / player.duration) * 100)
				setCurrPlayTime(convertToTimeString(player.currentTime * 1000) + ' / ' + convertToTimeString(player.duration * 1000))
				if (player.currentTime === player.duration) {
					setPlaying(false)
				}
			}
		}, 100)
		return () => {
			clearInterval(interval) // Stops from ticking when the component unmounts
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recording, playing])

	function onStopRecording() {
		setRecording(false)
		setRecordingExists(true)

		navigator.mediaDevices.getUserMedia(
			{ audio: true },
			(stream) => {
				// onSuccessFunction
				if (stream) {
					window.streamReference = stream
					const tracks = window.streamReference.getAudioTracks()
					if (tracks) tracks[0].stop()
				}
			},
			() => {} // onFailure function
		)

		// Stop recording and create the mp3 file
		recorder
			.stop()
			.getMp3()
			.then(([buffer, blob]) => {
				const file = new File(buffer, 'test.mp3', {
					type: blob.type,
					lastModified: Date.now(),
				})

				const url = URL.createObjectURL(file)

				dispatch(changeVoiceRecord(url))
				setPlayer(new Audio(url))

				scoreRecording()
			})
			.catch((e) => {
				alert('There was an error recording your message.')
				console.error(e)
			})
	}

	function scoreRecording() {
		let score = 0
		let tagRespScore = 0
		let newResponses = []

		// Score each tag
		for (let tag of tags) {
			tagRespScore = 0

			switch (tag.tag) {
				case 'VoiceRecordingLength':
					tagRespScore = scoreVoiceDuration(recorder.context.currentTime)
					break

				case 'VoiceUseMicrophone':
					tagRespScore = 0
					break

				default:
					break
			}

			score += tagRespScore

			// Add tag response to new responses
			const newResponse = { scoringTagID: tag.id, score: tagRespScore }
			newResponses.push(newResponse)
		}

		// Adds all the new responses, replacing old ones if necessary
		addScoringTagResponses(tagResponses, newResponses, dispatch)

		let found = -1

		// Searches for user response to the question
		for (let i = 0; i < userResponses.length; i++) {
			if (userResponses[i].questionID === props.question.id) {
				found = i
			}
		}

		if (found === -1) {
			// Creates new response if NOT found

			const newResponse = {
				questionID: props.question.id,
				userID: userID,
				response: '',
				score: score,
			}
			dispatch(changeUserResponses([...userResponses, newResponse]))
		} else {
			// Updates old response if found
			userResponses[found].score = score
			dispatch(changeUserResponses([...userResponses]))
		}
	}

	return (
		<div style={{ width: '100%' }}>
			<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 40 }}>
				In two minutes or less, tell us about your career goals.
			</Typography>

			<div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 20 }}>
				<div>
					{recordingExists ? (
						// Recording exists; show ui to play/pause/delete recording
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								flexDirection: 'column',
								height: 100,
								width: 500,
							}}
						>
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }}>
								{playing === false ? (
									// Audio not playing; show play button
									<Button
										onClick={() => {
											setPlaying(true)
											player.play()
										}}
									>
										<Play style={{ color: '#0066b2', height: 50, width: 50 }} />
									</Button>
								) : (
									// Audio is playing; show pause buttom

									<Button
										onClick={() => {
											setPlaying(false)
											player.pause()
										}}
									>
										<Pause style={{ color: '#0066b2', height: 50, width: 50 }} />
									</Button>
								)}

								<LinearProgress
									style={{ width: 200, height: 20 }}
									ref={(el) => {
										if (!el) return

										// Gets location of start of component
										xOffset = el.getBoundingClientRect().x
									}}
									onClick={(e) => {
										// Changes to current location of recording when clicked

										let position = e.pageX - xOffset

										if (position < 0) position = 0
										else if (position > 200) position = 200

										player.currentTime = (position / 200) * player.duration
										setProgress((player.currentTime / player.duration) * 100)
										setCurrPlayTime(
											convertToTimeString(player.currentTime * 1000) +
												' / ' +
												convertToTimeString(player.duration * 1000)
										)
									}}
									variant='determinate'
									value={progress}
								/>

								<Button style={{ color: 'black', fontSize: 20 }} disabled>
									{currPlayTime}
								</Button>

								<ReplayIcon
									style={{ color: '#0066b2', height: 50, width: 50 }}
									onClick={() => {
										player.pause()
										player.currentTime = 0
										setCurrPlayTime('00:00 / ' + convertToTimeString(player.duration * 1000))
										setProgress(0)
										setPlaying(false)
									}}
								></ReplayIcon>
							</div>

							<div style={{ paddingBottom: 20, alignItems: 'center', display: 'flex' }}>
								<VolumeUpIcon style={{ color: '#0066b2', height: 30, width: 30 }} />
								<input
									type='range'
									min={0}
									max={1}
									step={0.02}
									value={volume}
									onChange={(event) => {
										setVolume(event.target.valueAsNumber)
										player.volume = event.target.valueAsNumber
									}}
								/>
							</div>

							<Button
								onClick={() => {
									setCurrPlayTime('00:00')
									setProgress(0)
									setRecordingExists(false)
									if (playing) {
										player.pause()
									}
									dispatch(changeVoiceRecord(''))
									addVoiceUseMicResponse(tags, tagResponses, userResponses, props.question, userID, dispatch)
								}}
								style={{ color: 'white', background: '#0066b2', height: 50, width: 50 }}
							>
								<Delete style={{ color: 'white' }} />
							</Button>
						</div>
					) : // else if no recording exists

					recording ? (
						// Currently recording; show ui to stop recording and display time
						<div>
							<Button onClick={onStopRecording} data-cy='stop-recording'>
								<Stop style={{ color: '#0066b2', height: 50, width: 50 }} />
							</Button>
							<Timer startTime={startTime} />
						</div>
					) : (
						// else if not currently recording

						// show UI to start recording and a timer at 0 seconds
						<div>
							<Button
								onClick={() => {
									navigator.mediaDevices
										.getUserMedia({ audio: true })
										.then(() => {
											setStartTime(new Date().getTime())
											setRecording(true)
											recorder.start()
										})
										.catch(() => {
											alert(
												'It seems that you have blocked access to your microphone! If this was a mistake and you would like to record a message, you can adjust your permissions by clicking the camera icon on the right hand side of the URL bar.'
											)

											let found = -1

											for (let i = 0; i < userResponses.length; i++) {
												if (userResponses[i].questionID === 'Voice') {
													found = i
													break
												}
											}

											if (found === -1) {
												const newResponse = {
													questionID: 'Voice',
													userID: userID,
													response: 'VoiceDurationResponse',
													score: -1,
												}

												userResponses.push(newResponse)
												dispatch(changeUserResponses(userResponses))
											} else {
												userResponses[found].score = -1
												dispatch(changeUserResponses(userResponses))
											}
										})
								}}
								style={{ fontWeight: 'bold', color: '#0066b2', marginRight: 50 }}
							>
								<MicIcon style={{ color: '#0066b2', height: 50, width: 50 }} /> START RECORDING
							</Button>
							<Button style={{ color: 'black', fontSize: 20 }} disabled>
								{' '}
								00:00{' '}
							</Button>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default VoiceQuestion
