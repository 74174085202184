import { Typography, Checkbox, FormControlLabel } from '@mui/material'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { changeScoringTagResps, changeUserResponses } from '../actions'
import { getRelevantQuestions, getRelScoringTags } from '../functions/HelperFunctions'
import { scorePrivacyPolicy } from '../functions/ScoringTagFunctions'

import BTRLogo from '../images/BTRHireClientTransparent.png'

import { Storage } from 'aws-amplify'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'
import BlueButton from '../components/Button/BlueButton'

let startTime = new Date().getTime()

function PrivacyPolicy(props) {
	const [agreed, setAgreed] = useState(false)
	const privacyQuestion = getRelevantQuestions(
		useSelector((state) => state.questions),
		'PrivacyPolicy'
	)
	const tags = useSelector((state) => state.scoringTags)
	const responses = useSelector((state) => state.userResponses)
	const tagResponses = useSelector((state) => state.scoringTagResps)
	const testTimes = useSelector((state) => state.testTimes)
	const dispatch = useDispatch()

	function createPrivacyPolicyResponse() {
		if (privacyQuestion.length === 0) return

		const score = scorePrivacyPolicy(startTime, new Date().getTime())

		const response = {
			questionID: privacyQuestion[0].id,
			response: '',
			userID: '',
			notes: '',
			score: score,
		}

		const relTags = getRelScoringTags(tags, privacyQuestion[0])

		if (relTags.length === 0) return

		const tagResponse = {
			scoringTagID: relTags[0].id,
			userID: '',
			score: score,
		}

		dispatch(changeUserResponses([...responses, response]))
		dispatch(changeScoringTagResps([...tagResponses, tagResponse]))
	}

	useEffect(() => {
		if (!testTimes || testTimes.length === 0) dispatch({ type: 'START_NEW_TEST_TIME', payload: new Date().getTime() })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<PageContainer>
			<CardContainer>
				<div style={{ width: '100%', display: 'flex', alignItems: 'start', margin: '20px 0 0 -20px' }}>
					<img src={BTRLogo} alt='' style={{ width: 250 }} />
				</div>

				<Typography style={{ fontWeight: 'bold', fontSize: 22, textAlign: 'start', margin: '20px auto 0 0' }}>
					Before we get started
				</Typography>

				<Typography style={{ fontWeight: 'bold', fontSize: 16, textAlign: 'start', margin: '20px auto 0 0' }}>
					Privacy is very important to us
				</Typography>

				<Typography style={{ textAlign: 'start', margin: '20px 0 0 0' }}>
					We respect your right to privacy and take seriously our responsibilities regarding the collection, use and disclosure of
					your personal information.
					<br />
					<br />
					The information that you provide will only be used to assess your qualifications and suitability as a potential
					employee. You can review our full privacy policy here:
				</Typography>

				<BlueButton
					data-cy='view-privacy'
					style={{ margin: '30px 0 0 0' }}
					onClick={async () => {
						try {
							const result = await Storage.get('CaseStudyFiles/BTRPrivacyPolicy.pdf', {
								level: 'public',
								download: true,
							})
							const url = URL.createObjectURL(result.Body)

							const a = document.createElement('a')
							a.target = '_blank'
							a.href = url
							a.click()
						} catch (e) {
							console.error(e)
						}
					}}
				>
					View Privacy Policy
				</BlueButton>

				<Typography style={{ textAlign: 'start', margin: '30px 0 0 0' }}>
					I consent and agree that the information I provide in this application is correct and true to the best of my knowledge.
				</Typography>

				{/* <div style={{ display: 'flex', alignItems: 'start' }}> */}
				<FormControlLabel
					style={{ margin: '20px auto 0px -10px' }}
					label={<Typography style={{ fontSize: 16 }}> I Accept </Typography>}
					control={
						<Checkbox
							data-cy='privacy-agree'
							color='primary'
							checked={agreed}
							onClick={(e) => {
								setAgreed(e.target.checked)
							}}
						/>
					}
				/>
				{/* </div> */}

				<BlueButton
					disabled={!agreed}
					data-cy='privacy-continue'
					style={{
						width: 170,
						marginTop: 20,
					}}
					onClick={() => {
						createPrivacyPolicyResponse()
						props.nextPage()
					}}
				>
					Continue
				</BlueButton>
			</CardContainer>
		</PageContainer>
	)
}

export default PrivacyPolicy
