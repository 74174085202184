import React from 'react'
import '../../index.css'
import styles from './NavButton.module.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useContext } from 'react'
import { UnfinishedAttemptContext } from '../../contexts/UnfinishedAttemptContext'
import LoadingIcons from 'react-loading-icons'

function NavButton(props) {
	const { saveUnfinishedAttempt } = useContext(UnfinishedAttemptContext)

	return (
		<button
			variant='contained'
			data-cy={props.text === 'Next' ? 'next-page' : 'prev-page'}
			className={props.text === 'Back' || props.text === 'Cancel' ? styles['back-button'] : styles['next-button']}
			style={props.style}
			onClick={() => {
				if (props.loading) return
				if (props.saveOnPageChange) {
					saveUnfinishedAttempt()
				}
				props.onClick()
			}}
			disabled={props.disabled}
		>
			{props.loading ? (
				<LoadingIcons.Oval speed={1} stroke='lightblue' opacity={1} color='lightblue' height={24} strokeWidth={3} />
			) : (
				<>
					<span className={styles['text1']}>{props.text}</span>
					<span className={styles['text2']}>
						{props.text === 'Back' || props.text === 'Cancel' ? <ArrowBackIcon /> : <ArrowForwardIcon />}
					</span>
				</>
			)}
		</button>
	)
}

export default NavButton
