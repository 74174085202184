import './App.css'

import Welcome from './pages/Welcome'
import WelcomeForm from './pages/WelcomeForm'
import PositionDetails from './pages/PositionDetails'
import AboutYourself from './pages/AboutYourself'
import YourExperience from './pages/YourExperience'
import KnowledgeAssessment from './pages/KnowledgeAssessment'
import CaseStudy from './pages/CaseStudy'
import LastQuestion from './pages/LastQuestion'
import LastQuestionAnswer from './pages/LastQuestionAnswer'
import ConfirmEmail from './pages/ConfirmEmail'
import Thanks from './pages/Thanks'
import Voice from './pages/Voice'
import LandingPage from './pages/LandingPage'

import React from 'react'
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'
import { useSelector, useDispatch } from 'react-redux'
import { changePageIndex } from './actions'
import PrivacyPolicy from './pages/PrivacyPolicy'

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles'

import './index.css'
import WarningCover from './components/WarningCover/WarningCover'
import SelfIdentification from './pages/SelfIdentification'
import SuccessPage from './pages/SuccessPage'
import { useEffect } from 'react'
import { UnfinishedAttemptContextWrapper } from './contexts/UnfinishedAttemptContext'

Amplify.configure(awsconfig)

const theme = createTheme({
	typography: { fontFamily: "'Poppins', sans-serif" },
	components: {
		MuiButton: {
			text: {
				// Some CSS
				fontSize: 16,
			},
		},
		MuiFormHelperText: {
			root: { fontSize: 14 },
		},
		MuiTextField: {
			defaultProps: {
				variant: 'standard',
			},
		},
	},
})

function App() {
	const pageIndex = useSelector((state) => state.pageIndex)
	const dispatch = useDispatch()

	useEffect(() => {
		document.body.scrollTop = document.documentElement.scrollTop = 0
		dispatch({ type: 'UPDATE_TEST_END_TIME', payload: { endTime: new Date().getTime() } })

		// eslint-disable-next-line
	}, [pageIndex])

	function renderPage(index) {
		switch (index) {
			case 0:
				return <LandingPage nextPage={() => dispatch(changePageIndex(1))} />
			case 1:
				return <Welcome nextPage={() => dispatch(changePageIndex(1.5))} />
			case 1.5:
				return <PrivacyPolicy nextPage={() => dispatch(changePageIndex(2))} />
			case 2:
				return <WelcomeForm nextPage={() => dispatch(changePageIndex(3))} />
			case 3:
				return <PositionDetails nextPage={() => dispatch(changePageIndex(4))} />
			case 4:
				return <AboutYourself prevPage={() => dispatch(changePageIndex(3))} nextPage={() => dispatch(changePageIndex(5))} />
			case 5:
				return <YourExperience prevPage={() => dispatch(changePageIndex(4))} nextPage={() => dispatch(changePageIndex(6))} />
			case 6:
				return <KnowledgeAssessment prevPage={() => dispatch(changePageIndex(5))} nextPage={() => dispatch(changePageIndex(7))} />
			case 7:
				return <CaseStudy prevPage={() => dispatch(changePageIndex(6))} nextPage={() => dispatch(changePageIndex(8))} />
			case 8:
				return <Voice prevPage={() => dispatch(changePageIndex(7))} nextPage={() => dispatch(changePageIndex(9))} />
			case 9:
				return <LastQuestion prevPage={() => dispatch(changePageIndex(8))} nextPage={() => dispatch(changePageIndex(10))} />
			case 10:
				return <LastQuestionAnswer prevPage={() => dispatch(changePageIndex(9))} nextPage={() => dispatch(changePageIndex(11))} />
			case 11:
				return <ConfirmEmail prevPage={() => dispatch(changePageIndex(10))} nextPage={() => dispatch(changePageIndex(12))} />
			case 12:
				return <Thanks nextPage={() => dispatch(changePageIndex(13))} />
			case 13:
				return <SelfIdentification prevPage={() => dispatch(changePageIndex(12))} nextPage={() => dispatch(changePageIndex(14))} />
			case 14:
				return <SuccessPage />
			default:
				return <LandingPage nextPage={() => dispatch(changePageIndex(1))} />
		}
	}

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<div className='App'>
					<UnfinishedAttemptContextWrapper>{renderPage(pageIndex)}</UnfinishedAttemptContextWrapper>
					<WarningCover />
				</div>
			</ThemeProvider>
		</StyledEngineProvider>
	)
}

export default App
