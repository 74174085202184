import React, { useState } from 'react'
import NavButton from '../components/Button/NavButton'
import { useSelector } from 'react-redux'
import { checkSubmittedQuestions, getRelevantQuestions } from '../functions/HelperFunctions'

import TimedLongResponse from '../questions/TimedLongResponse'
import LongResponse from '../questions/LongResponse'
import PositionInfoButton from '../components/PositionInfoButton'
import PositionDetailsPopup from '../components/PositionDetailsPopup'
import HelpButton from '../components/HelpButton'

import './Pages.css'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'

function LastQuestionAnswer(props) {
	const questions = getRelevantQuestions(
		useSelector((state) => state.questions),
		'LastQuestion'
	)
	const timedResponses = useSelector((state) => state.timedUserResponses)
	const [showInfo, setShowInfo] = useState(false)
	const userID = useSelector((state) => state.id)
	const logo = useSelector((state) => state.logo)

	const timedQuestions = questions.filter((question) => question.format === 'TimedLongResponse')
	const unsubmittedQuestions = checkSubmittedQuestions(timedResponses, timedQuestions)
	const canNavigate = unsubmittedQuestions.length === 0

	return (
		<PageContainer>
			<CardContainer style={{ paddingTop: 0 }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: 'calc(100% + 25px)',
						minHeight: 75,
					}}
				>
					<img src={logo} alt='' style={{ maxHeight: 100, maxWidth: 200, display: 'flex', justifyContent: 'start' }} />
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<PositionInfoButton setTrigger={setShowInfo} trigger={showInfo} />
						<HelpButton />
					</div>
				</div>

				{questions.map((question) => {
					if (question.format === 'LongResponse') {
						return <LongResponse disabled={false} question={question} userID={userID} key={question.id} />
					} else if (question.format === 'TimedLongResponse') {
						return <TimedLongResponse question={question} userID={userID} key={question.id} />
					}
					return <div />
				})}
				<div className='NavigationContainer'>
					<NavButton text='Back' onClick={() => props.prevPage()} saveOnPageChange disabled={!canNavigate} />
					<NavButton
						text='Next'
						onClick={() => {
							if (checkSubmittedQuestions(timedResponses, questions)) {
								props.nextPage()
							} else {
								alert('You must submit your answer before moving on to the next page.')
							}
						}}
						saveOnPageChange
						disabled={!canNavigate}
					/>
				</div>
			</CardContainer>
			<div style={{ display: 'flex', justifyContent: 'end' }}>
				{showInfo ? <PositionDetailsPopup setTrigger={setShowInfo} /> : <div />}
			</div>
		</PageContainer>
	)
}

export default LastQuestionAnswer
