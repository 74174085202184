import React, { useState, useEffect } from 'react'
import { FormControl, Typography, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getRelevantQuestionAnswers, getRelScoringTags } from '../functions/HelperFunctions'
import { addSingleResponse, findDefaultValue } from '../functions/UserResponseFunctions'

import Notes from './Notes'
import { deleteScoringTagResponse, scoreMultipleChoiceTags } from '../functions/ScoringTagFunctions'
import { changeScoringTagResps } from '../actions'

function MultipleChoice(props) {
	const dispatch = useDispatch()
	const userResponses = useSelector((state) => state.userResponses)
	const questionAnswers = getRelevantQuestionAnswers(props.questionAnswers, props.question.id)
	const tags = getRelScoringTags(
		useSelector((state) => state.scoringTags),
		props.question
	)
	const tagResponses = useSelector((state) => state.scoringTagResps)
	const [value, setValue] = useState('')

	useEffect(() => {
		findDefaultValue(props.question.id, userResponses, setValue)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div key={props.question.questionText}>
			<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 10 }}>
				{props.questionNumber !== -1 ? props.questionNumber + '.' : ''} {props.question.questionText}
			</Typography>
			<FormControl style={{ display: 'flex', justifyContent: 'start' }}>
				<RadioGroup style={{ fontSize: 40, paddingBottom: 10, display: 'flex', justifyContent: 'start' }} value={value}>
					{questionAnswers.map((questionAnswer) => {
						return (
							<FormControlLabel
								key={questionAnswer.answerText}
								value={questionAnswer.answerText}
								control={<Radio size='small' disableRipple color='primary' />}
								label={<Typography style={{ fontSize: 16, textAlign: 'start' }}> {questionAnswer.answerText} </Typography>}
								onChange={() => {
									setValue(questionAnswer.answerText)

									/* Deletes all old tags for this question */
									const newTagResponses = tagResponses.filter((tagResp) => {
										for (const tag of tags) {
											if (tag.id === tagResp.scoringTagID) {
												return false
											}
										}
										return true
									})
									dispatch(changeScoringTagResps(newTagResponses))

									for (const tag of tags) {
										deleteScoringTagResponse(tagResponses, tag, dispatch)
									}

									const newResponse = {
										questionID: props.question.id,
										response: questionAnswer.answerText,
										notes: '',
										score: questionAnswer.score,
									}

									scoreMultipleChoiceTags(questionAnswer.answerText, tags, newTagResponses, dispatch)
									addSingleResponse(newResponse, userResponses, props.question, dispatch)
								}}
							/>
						)
					})}
				</RadioGroup>
			</FormControl>
			{props.question.hasNotes ? (
				// Does have notes
				<Notes question={props.question} userID={props.userID} /> // OR
			) : (
				// Does not have notes
				<div />
			)}
		</div>
	)
}

export default MultipleChoice
