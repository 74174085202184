import { API, graphqlOperation } from 'aws-amplify'
import {
	createScoringTagResponse,
	createUserResponse,
	updateAccessCode,
	createUser,
	createErrorLog,
	deleteUnfinishedAttempt as deleteUnfinishedAttemptMutation,
} from '../graphql/mutations'
import { questionsByTest, questionAnswersByQuestion, tagsByQuestion, accessCodeByCodeString } from '../graphql/queries'
import {
	changeQuestions,
	changeQuestionAnswers,
	changeLogo,
	changeCaseStudyFiles,
	changeScoringTags,
	changeSelfIdentificationQuestions,
} from '../actions'
import { Storage } from 'aws-amplify'

import { sumScore } from './HelperFunctions'
import { getTestForApplicant } from '../graphql/customqueries'
import { calculateTotalTimeFromTimeBlocks } from './Utils'

/**
 * 	Uploads a list of items to the appropriate table in the database. Logs any errors with uploading specific items to the console.
 *
 * @param {[Object]} items - List of items to upload to the server
 * @param {String} itemName - Name of the item ie ScoringTagResponse
 * @param {Function} queryFunction - Graphql function used to upload
 * @param {Function} convertToDatabaseFormat - Convert an item to the required format for the database schema
 */
async function pushItemsToDatabase(items, itemName, queryFunction, userID, testID, convertToDatabaseFormat) {
	if (!items || !items.length) {
		console.error(`There are no ${itemName}s to push.`)
		return false
	}

	let result = false
	console.log(`Attempting to push ${items.length} ${itemName}s to the database.`)

	// Introduce errors for testing purposes
	// if (items[4] && itemName === 'UserResponse') {
	// 	items[4].questionID = null
	// }
	// if (items[11]) {
	// 	items[11] = null
	// }

	// Converts each item and attempts to upload it
	const promises = items.map((item) => {
		try {
			const mappedItem = convertToDatabaseFormat(item)
			return API.graphql({
				query: queryFunction,
				variables: {
					input: mappedItem,
				},
			})
		} catch (e) {
			console.log(e)
			return Promise.reject(e)
		}
	})

	await Promise.all(promises)
		.then(() => {
			console.log(`All ${itemName}s uploaded successfully.`)
			result = true
		})
		.catch(() => {
			console.error(`At least one ${itemName} failed to upload.`)
			result = false

			// Outputs reason for failure for each failed upload
			Promise.allSettled(promises).then((results) => {
				results.forEach((result) => {
					if (result.status === 'rejected') {
						submitErrorLog(result.reason, itemName, userID, testID)
					}
				})
			})
		})

	return result
}

// Pushes the untimed UserResponses to the database
export async function pushResponsesToDatabase(userResponses, userID, testID) {
	const result = await pushItemsToDatabase(userResponses, 'UserResponse', createUserResponse, userID, testID, (userResponse) => {
		return {
			userID: userID,
			response: userResponse.response,
			notes: userResponse.notes,
			questionID: userResponse.questionID,
			score: userResponse.score,
			timeTaken: 0,
		}
	})
	return result
}

// Pushes the timed UserResponses to the database
export async function pushTimedResponsesToDatabase(responses, userID, testID) {
	const result = await pushItemsToDatabase(responses, 'TimedUserResponse', createUserResponse, userID, testID, (response) => {
		return {
			userID: userID,
			response: response.response,
			notes: response.notes,
			questionID: response.questionID,
			score: response.score,
			timeTaken: calculateTotalTimeFromTimeBlocks(response.timeTaken),
		}
	})
	return result
}

// Pushes the ScoringTagResponses to the database
export async function pushScoringTagResps(responses, userID, testID) {
	const result = await pushItemsToDatabase(responses, 'ScoringTagResponse', createScoringTagResponse, userID, testID, (response) => {
		return {
			scoringTagID: response.scoringTagID,
			userID: userID,
			score: response.score,
		}
	})
	return result
}

export async function deleteUnfinishedAttempt(personalAccessCode) {
	try {
		await API.graphql({ query: deleteUnfinishedAttemptMutation, variables: { input: { id: personalAccessCode } } })
	} catch (e) {
		console.error(e)
	}
}

export async function loadLogo(dispatch, testID) {
	try {
		const testData = await API.graphql({ query: getTestForApplicant, variables: { id: testID } })
		const test = testData.data.getTest

		await Storage.get(test.logoPath, {
			level: 'public',
		})
			.then(async (result) => {
				let blob = await fetch(result).then((r) => r.blob())
				dispatch(changeLogo(URL.createObjectURL(blob)))
			})
			.catch((err) => {
				console.error('Error getting test logo.', err)
			})
	} catch (e) {
		console.error('Error getting logo path.', e)
	}
}

export async function loadCaseStudyFiles(dispatch, testID) {
	try {
		const testData = await API.graphql({ query: getTestForApplicant, variables: { id: testID } })
		const test = testData.data.getTest

		const caseStudyFiles = []

		for (let item of test.casestudyFiles) {
			let nameFilePair = item.split(':')

			const newItem = {
				name: nameFilePair[0],
				file: nameFilePair[1],
			}
			caseStudyFiles.push(newItem)
		}

		dispatch(changeCaseStudyFiles(caseStudyFiles))
	} catch (e) {
		console.error('Error case study files.', e)
	}
}

export async function loadSelfIdentificationQuestions(dispatch, testID) {
	try {
		const testData = await API.graphql({
			query: getTestForApplicant,
			variables: { id: testID },
		})
		const test = testData.data.getTest

		dispatch(changeSelfIdentificationQuestions(test.selfIdentificationQuestions))
	} catch (e) {
		console.error('Error get Self Identification Questions.', e)
	}
}

// Returns a promise for finding question answers for each question
async function getQuestionAnswersPromise(questionID) {
	return new Promise(async (resolve, reject) => {
		try {
			const questionAnswersData = await API.graphql({
				query: questionAnswersByQuestion,
				variables: { questionID },
			})
			const questionAnswers = questionAnswersData.data.questionAnswersByQuestion.items

			resolve(questionAnswers)
		} catch (e) {
			reject(e)
		}
	})
}

// Returns a promise for finding scoring tags for each question
async function getScoringTagsPromises(questionID) {
	return new Promise(async (resolve, reject) => {
		try {
			const tagsData = await API.graphql({
				query: tagsByQuestion,
				variables: { questionID },
			})
			const tags = tagsData.data.tagsByQuestion.items

			resolve(tags)
		} catch (e) {
			reject(e)
		}
	})
}

// Gets the results of all the promises, and updates the Redux store with all the question answers and scoring tags
async function dispatchPromises(questionAnswerPromises, scoringTagPromises, dispatch) {
	const allQuestionAnswers = []
	const allScoringTags = []

	// Adds all the scoring tags
	await Promise.all(scoringTagPromises)
		.then((results) => {
			for (const result of results) {
				if (result.length > 0) {
					allScoringTags.push(...result)
				}
			}
		})
		.catch((err) => {
			console.error(err)
			return
		})

	// Adds all the question answers
	await Promise.all(questionAnswerPromises)
		.then((results) => {
			for (const result of results) {
				if (result.length > 0) {
					allQuestionAnswers.push(...result)
				}
			}
		})
		.catch((err) => {
			console.error(err)
			return
		})

	dispatch(changeQuestionAnswers(allQuestionAnswers))
	dispatch(changeScoringTags(allScoringTags))
}

// Gets the Questions, Scoring Tags, and QuestionAnswers for a test that has the id of testID
export async function loadTest(dispatch, testID) {
	try {
		// Load questions
		const questionsData = await API.graphql({
			query: questionsByTest,
			variables: { limit: 100, testID },
		})
		const questions = questionsData.data.questionsByTest.items
		dispatch(changeQuestions(questions))

		// Questions that do not need to be queried for
		const ignoredSections = ['CaseStudyInstructions', 'CaseStudyDescription']
		const questionAnswerIgnoredFormats = ['Voice', 'ShortResponse', 'Memo', 'LongResponse', 'TimedLongResponse']

		// Gets an array of promises returning arrays of question answers for each question
		const questionAnswerPromises = []
		for (const question of questions) {
			if (ignoredSections.includes(question.section)) continue
			if (questionAnswerIgnoredFormats.includes(question.format)) continue

			questionAnswerPromises.push(getQuestionAnswersPromise(question.id))
		}

		// Gets an array of promises returning arrays of scoring for each question
		const scoringTagPromises = []
		for (const question of questions) {
			if (ignoredSections.includes(question.section)) continue

			scoringTagPromises.push(getScoringTagsPromises(question.id))
		}

		dispatchPromises(questionAnswerPromises, scoringTagPromises, dispatch)
	} catch (e) {
		console.error('Error loading test', e)
	}
}

export async function uploadVoiceRecording(voiceRecord, userID) {
	if (voiceRecord === undefined || voiceRecord === null || voiceRecord === '') {
		console.log('Voice record empty. Returning.')
		return true
	}

	const fn = 'VoiceRecordings/' + userID + 'VoiceRecording'

	try {
		const blob = await fetch(voiceRecord).then((r) => r.blob())
		await Storage.put(fn, blob, { contentType: 'audio/mp3' })
		console.log('Successfully uploaded voice recording.')
		return true
	} catch (e) {
		console.error('Error uploading voice recording', e)
		return false
	}
}

// Checks to see if the code a user submitted exists, has not exceeded the number of attempts allowed, and is not closed
// Returns the Test ID of the Test that the code is for, if found and valid
export async function verifyAccessCode(userCode) {
	try {
		const codesData = await API.graphql({
			query: accessCodeByCodeString,
			variables: { code: userCode },
			limit: 10,
		})
		const codes = codesData.data.accessCodeByCodeString.items

		if (codes.length === 0) return '' // Could not find code

		const code = codes[0]

		if (code.timesUsed >= codes.allowedUses) return 'LIMIT'
		else if (codes.status === 'closed') return 'CLOSED'

		return code.testID
	} catch (e) {
		console.error(e)
	}

	return ''
}

export async function updateAccessCodeAttempts(code) {
	const codesData = await API.graphql({
		query: accessCodeByCodeString,
		variables: { code },
		limit: 10,
	})
	const codes = codesData.data.accessCodeByCodeString.items

	if (codes.length === 0) {
		// Could not find code
		console.error('Error: Could not find access code to update.')
		return
	}

	const updatedCode = {
		id: codes[0].id,
		timesUsed: codes[0].timesUsed + 1,
	}

	await API.graphql(graphqlOperation(updateAccessCode, { input: updatedCode }))
}

export async function submitUser(
	userID,
	firstName,
	lastName,
	email,
	phoneNumber,
	address,
	city,
	province,
	postalCode,
	testTimes,
	caseStudyTimes,
	responses,
	timedResponses,
	infoButtonScore,
	testID,
	codeUsed
) {
	try {
		await API.graphql({
			query: createUser,
			variables: {
				input: {
					id: userID,
					email,
					firstName,
					lastName,
					address,
					city,
					phone: phoneNumber,
					province,
					postalCode,
					status: 'APPLIED',
					testID,
					score: sumScore(responses, timedResponses, infoButtonScore),
					testTime: calculateTotalTimeFromTimeBlocks(testTimes),
					caseStudyTime: calculateTotalTimeFromTimeBlocks(caseStudyTimes),
					shortlisted: false,
					codeUsed,
				},
			},
		})
		return true
	} catch (e) {
		submitErrorLog(e, 'User', userID, testID)
		console.error('ERROR: FAILED TO SUBMIT USER!', e)
		return false
	}
}

/**
 * Submits the error message to the ErrorLog table with information about which user was affected and for what test.
 *
 * @param {Error} reason - The reason that the operation failed. Taken from a catch statement ie catch(e => ...).
 * @param {String} name - The name of the table you were trying to upload
 * @param {ID} userID
 * @param {ID} testID
 */
function submitErrorLog(reason, name, userID, testID) {
	let errorString = reason.toString()

	// We check if errorString is [object Object] because if the error comes from a GraphQL issue then toString(result.reason) will
	// not result in a valid string. So, in this case we must use JSON.Stringify to get a readable error message.
	if (errorString === '[object Object]') {
		errorString = JSON.stringify(reason)
	}

	console.error(`Submitting ${name} failed with error: ${errorString}`)
	API.graphql({
		query: createErrorLog,
		variables: {
			input: {
				userID,
				testID,
				table: name,
				message: errorString,
			},
		},
	}).catch((e) => {
		console.error('Error uploading error log: ', e)
	})
}
