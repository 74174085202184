import React from 'react'
import { Typography } from '@mui/material'

function PageTitle({ children, ...props }) {
	return (
		<Typography
			component='h1'
			sx={{ fontSize: 18, color: 'rgb(40, 40, 40)', marginBottom: '20px', fontWeight: 'bold', alignSelf: 'start' }}
			{...props}
		>
			{children}
		</Typography>
	)
}

export default PageTitle
