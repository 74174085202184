import { TextField } from '@mui/material'
import { React, useContext, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
	changeFirstName,
	changeLastName,
	changeAddress,
	changeCity,
	changePostalCode,
	changeEmail,
	changeProvince,
	changePhoneNumber,
	changeID,
} from '../actions'
import NavButton from '../components/Button/NavButton'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'

import { UnfinishedAttemptContext } from '../contexts/UnfinishedAttemptContext'

const styles = {
	box: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
	},
	item: {
		padding: '10px',
		width: '200px',
	},
}

const inputStyle = { WebkitBoxShadow: '0 0 0 1000px white inset' } // This affects how the fields look when autofilled
const helperTextStyle = { fontSize: 14 }

function WelcomeForm(props) {
	// Use States
	const [firstNameError, setFirstNameError] = useState(false)
	const [lastNameError, setLastNameError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [cityError, setCityError] = useState(false)
	const [addressError, setAddressError] = useState(false)
	const [phoneError, setPhoneError] = useState(false)
	const [postalError, setPostalError] = useState(false)
	const [provinceError, setProvinceError] = useState(false)

	// Redux State
	const firstName = useSelector((state) => state.firstName)
	const lastName = useSelector((state) => state.lastName)
	const address = useSelector((state) => state.address)
	const city = useSelector((state) => state.city)
	const postalCode = useSelector((state) => state.postalCode)
	const email = useSelector((state) => state.email)
	const province = useSelector((state) => state.province)
	const phoneNumber = useSelector((state) => state.phoneNumber)
	const logo = useSelector((state) => state.logo)
	const dispatch = useDispatch()

	const { createUnfinishedAttempt } = useContext(UnfinishedAttemptContext)

	return (
		<PageContainer>
			<CardContainer width={450}>
				<img src={logo} alt='' style={{ maxHeight: 100, maxWidth: 200, margin: '0 auto 0 0' }} />
				<div>
					<TextField
						style={styles.item}
						data-cy='firstname-input'
						error={firstNameError}
						helperText={firstNameError ? 'First name is required.' : 'First Name'}
						onChange={(e) => dispatch(changeFirstName(e.target.value))}
						value={firstName}
						inputProps={{ style: inputStyle, maxLength: 25 }}
						FormHelperTextProps={{ style: helperTextStyle }}
					/>
					<TextField
						style={styles.item}
						data-cy='lastname-input'
						error={lastNameError}
						helperText={lastNameError ? 'Last name is required.' : 'Last Name'}
						value={lastName}
						onChange={(e) => dispatch(changeLastName(e.target.value))}
						inputProps={{ style: inputStyle, maxLength: 25 }}
						FormHelperTextProps={{ style: helperTextStyle }}
					/>
				</div>
				<div>
					<TextField
						style={styles.item}
						data-cy='address-input'
						error={addressError}
						helperText={addressError ? 'Address is required.' : 'Address'}
						value={address}
						onChange={(e) => dispatch(changeAddress(e.target.value))}
						inputProps={{ style: inputStyle, maxLength: 60 }}
						FormHelperTextProps={{ style: helperTextStyle }}
					/>
					<TextField
						style={styles.item}
						data-cy='city-input'
						error={cityError}
						helperText={cityError ? 'City is required.' : 'City'}
						value={city}
						onChange={(e) => dispatch(changeCity(e.target.value))}
						inputProps={{ style: inputStyle, maxLength: 30 }}
						FormHelperTextProps={{ style: helperTextStyle }}
					/>
				</div>
				<div>
					<TextField
						style={styles.item}
						data-cy='province-input'
						value={province}
						error={provinceError}
						helperText={provinceError ? 'Province is required.' : 'Province/State'}
						onChange={(e) => dispatch(changeProvince(e.target.value))}
						inputProps={{ style: inputStyle, maxLength: 30 }}
						FormHelperTextProps={{ style: helperTextStyle }}
					/>
					<TextField
						style={styles.item}
						data-cy='postalcode-input'
						error={postalError}
						helperText={postalError ? 'Postal Code is required.' : 'Postal Code'}
						value={postalCode}
						onChange={(e) => dispatch(changePostalCode(e.target.value))}
						inputProps={{ style: inputStyle, maxLength: 10 }}
						FormHelperTextProps={{ style: helperTextStyle }}
					/>
				</div>
				<TextField
					data-cy='email-input'
					style={{ width: 420, margin: '10px 0' }}
					error={emailError}
					helperText={emailError ? 'Email is required.' : 'Email'}
					value={email}
					onChange={(e) => dispatch(changeEmail(e.target.value))}
					inputProps={{ style: inputStyle, maxLength: 50 }}
					FormHelperTextProps={{ style: helperTextStyle }}
				/>
				<TextField
					variant='standard'
					data-cy='phone-input'
					error={phoneError}
					style={{ width: 420, margin: '10px 0' }}
					helperText={phoneError ? 'Phone number is required.' : 'Phone Number'}
					value={phoneNumber}
					onChange={(e) => dispatch(changePhoneNumber(e.target.value))}
					inputProps={{ style: inputStyle, maxLength: 15 }}
					FormHelperTextProps={{ style: helperTextStyle }}
				/>
				<div
					style={{
						display: 'flex',
						justifyContent: 'end',
						alignItems: 'center',
						marginTop: 30,
						width: '100%',
					}}
				>
					{/* <img src={BTRLogo} alt='' style={{ width: 150 }} /> */}

					<NavButton
						text='Next'
						data-cy='welcomeform-continue'
						onClick={async () => {
							let isError = false

							if (firstName === 'TestPass1') {
								props.nextPage()
								return
							}

							if (firstName === '') {
								setFirstNameError(true)
								isError = true
							} else {
								setFirstNameError(false)
							}

							if (lastName === '') {
								setLastNameError(true)
								isError = true
							} else {
								setLastNameError(false)
							}

							if (postalCode === '') {
								setPostalError(true)
								isError = true
							} else {
								setPostalError(false)
							}

							if (province === '') {
								setProvinceError(true)
								isError = true
							} else {
								setProvinceError(false)
							}

							if (email === '') {
								setEmailError(true)
								isError = true
							} else {
								setEmailError(false)
							}

							if (address === '') {
								setAddressError(true)
								isError = true
							} else {
								setAddressError(false)
							}

							if (city === '') {
								setCityError(true)
								isError = true
							} else {
								setCityError(false)
							}

							if (phoneNumber === '') {
								setPhoneError(true)
								isError = true
							} else {
								setPhoneError(false)
							}

							if (isError) return

							dispatch(changeID(firstName.substring(0, 1) + lastName + new Date().getTime()))
							const result = await createUnfinishedAttempt()

							if (!result) {
								return
							}

							props.nextPage()
						}}
					/>
				</div>
			</CardContainer>
		</PageContainer>
	)
}

export default WelcomeForm
