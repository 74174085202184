import React, { useState } from 'react'
import NavButton from '../components/Button/NavButton'
import { useSelector } from 'react-redux'
import { getRelevantQuestions } from '../functions/HelperFunctions'
import MultipleChoice from '../questions/MultipleChoice'
import ShortResponse from '../questions/ShortResponse'
import MultipleCheckbox from '../questions/MultipleCheckbox'
import LongResponse from '../questions/LongResponse'
import PositionInfoButton from '../components/PositionInfoButton'
import PositionDetailsPopup from '../components/PositionDetailsPopup'

import './Pages.css'
import HelpButton from '../components/HelpButton'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'
import PageTitle from '../components/Heading/PageTitle'

function AboutYourself(props) {
	const questions = getRelevantQuestions(
		useSelector((state) => state.questions),
		'AboutYourself'
	)
	const questionAnswers = useSelector((state) => state.questionAnswers)
	const userID = useSelector((state) => state.id)
	const [showInfo, setShowInfo] = useState(false)

	return (
		<PageContainer>
			<CardContainer>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginBottom: 15,
						width: '100%',
					}}
				>
					<PageTitle style={{ margin: 'auto 0 auto' }}>Tell us about yourself</PageTitle>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<PositionInfoButton setTrigger={setShowInfo} trigger={showInfo} affectScore={true} />
						<HelpButton />
					</div>
				</div>

				{questions.map((question) => {
					if (question.format === 'MultipleChoice') {
						return <MultipleChoice question={question} questionAnswers={questionAnswers} userID={userID} key={question.id} />
					} else if (question.format === 'ShortResponse') {
						return <ShortResponse question={question} userID={userID} key={question.id} />
					} else if (question.format === 'Checkbox') {
						return <MultipleCheckbox question={question} questionAnswers={questionAnswers} userID={userID} key={question.id} />
					} else if (question.format === 'LongResponse') {
						return <LongResponse question={question} userID={userID} key={question.questionText} />
					} else {
						return <div />
					}
				})}
				<div className='NavigationContainer'>
					<NavButton text='Back' onClick={() => props.prevPage()} saveOnPageChange />
					<NavButton text='Next' onClick={() => props.nextPage()} saveOnPageChange />
				</div>
			</CardContainer>
			<div style={{ display: 'flex', justifyContent: 'end' }}>
				{showInfo ? <PositionDetailsPopup setTrigger={setShowInfo} /> : <div />}
			</div>
		</PageContainer>
	)
}

export default AboutYourself
