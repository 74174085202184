import { changeTimedUserResponses, changeUserResponses } from '../actions'

// Adds the response to the list of userResponses, deleting old versions
export function addSingleResponse(newResponse, userResponses, question, dispatch) {
	if (!newResponse) return

	const newUserResponses = [...userResponses]

	// See if response already exists. If it does, update it
	for (let i = 0; i < userResponses.length; i++) {
		if (userResponses[i].questionID === question.id) {
			for (const key in newResponse) {
				userResponses[i][key] = newResponse[key]
			}

			dispatch(changeUserResponses(newUserResponses))
			return
		}
	}

	// Add the new response if it did not already exist
	newUserResponses.push(newResponse)
	dispatch(changeUserResponses(newUserResponses))
}

// Searches for a timed response. If it is found, returns the response.
// Otherwise, it "starts" the response by getting the start time and pushing a blank response
// to the list of timedResponses
export function tryToStartTimedResponse(startTime, timedResponses, question, dispatch) {
	for (const response of timedResponses) {
		if (response.questionID === question.id) {
			return response
		}
	}

	const newTimedResponses = [...timedResponses]

	newTimedResponses.push({
		questionID: question.id,
		response: '',
		notes: '',
		score: 0,
		submitted: false,
		startTime,
		endTime: 0,
	})

	dispatch(changeTimedUserResponses(newTimedResponses))

	return null
}

export function createBlankResponse(timedResponses, question, dispatch) {
	dispatch(
		changeTimedUserResponses([
			...timedResponses,
			{
				questionID: question.id,
				response: '',
				notes: '',
				score: 0,
				submitted: false,
				startTime: new Date().getTime(),
				endTime: 0,
			},
		])
	)
}

// Searches for a timed response. If it is found, adds a new TimeBlock to the list of timeTaken.
// If it is not found, it initiates the response by adding a blank response with a new TimeBlock
export function startOrUpdateTimedResponseTime(timedResponses, question, dispatch) {
	const newTimedResponses = [...timedResponses]
	const currTime = new Date().getTime()

	const response = newTimedResponses.find((response) => response.questionID === question.id)

	if (response) {
		response.timeTaken.push({ startTime: currTime, endTime: currTime })
	} else {
		newTimedResponses.push({
			questionID: question.id,
			response: '',
			notes: '',
			score: 0,
			submitted: false,
			timeTaken: [{ startTime: currTime, endTime: currTime }],
		})
	}

	dispatch(changeTimedUserResponses(newTimedResponses))
	return response
}

export function updateTimedResponse(updates, timedResponses, question, dispatch) {
	if (!updates) return

	const newTimedResponses = [...timedResponses]
	const response = newTimedResponses.find((response) => response.questionID === question.id)

	if (!response || !response.timeTaken) {
		console.error('Error: Could not find response to update.')
		return
	}

	if (!response.timeTaken || response.timeTaken.length === 0) {
		console.error('Error: This response does not seem to be initiated yet.')
		return
	}

	response.timeTaken[response.timeTaken.length - 1].endTime = new Date().getTime()

	for (const key in updates) {
		response[key] = updates[key]
	}

	dispatch(changeTimedUserResponses(newTimedResponses))
	return
}

// Deletes a response to a Checkbox question by searching for the questionID and the answerText to match the questionAnswer
export function deleteResponseByAnswerText(userResponses, question, answerText, dispatch) {
	const newUserResponses = [...userResponses]

	for (let i = 0; i < newUserResponses.length; i++) {
		if (question.id === newUserResponses[i].questionID && newUserResponses[i].response === answerText) {
			newUserResponses.splice(i, 1)
			dispatch(changeUserResponses(newUserResponses))
			break
		}
	}
}

export function findDefaultValue(questionID, userResponses, setDefaultValue) {
	for (const response of userResponses) {
		if (questionID === response.questionID) {
			setDefaultValue(response.response)
			return true
		}
	}

	return false
}
