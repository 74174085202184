import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import Notes from './Notes'
import { getRelScoringTags, onTabPress } from '../functions/HelperFunctions'
import { useRef } from 'react'
import { scoreLongResponseTags } from '../functions/ScoringTagFunctions'
import { addSingleResponse, findDefaultValue } from '../functions/UserResponseFunctions'

function LongResponse(props) {
	const ref = useRef()

	const userResponses = useSelector((state) => state.userResponses)
	const tags = getRelScoringTags(
		useSelector((state) => state.scoringTags),
		props.question
	)
	const tagResponses = useSelector((state) => state.scoringTagResps)
	const dispatch = useDispatch()

	const [defaultValue, setDefaultValue] = useState('')

	useEffect(() => {
		findDefaultValue(props.question.id, userResponses, setDefaultValue)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
			<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 10 }}>{props.question.questionText}</Typography>
			<textarea
				style={{ height: 200, width: '100%', resize: 'none', fontSize: 16, paddingBottom: 15, fontFamily: "'Poppins', sans-serif" }}
				defaultValue={defaultValue}
				maxLength='3000'
				ref={ref}
				onKeyDown={(e) => {
					if (e.key === 'Tab') {
						onTabPress(ref, e)
					}
				}}
				onBlur={(e) => {
					let score = scoreLongResponseTags(e.target.value, tags, tagResponses, dispatch)

					const newResponse = {
						questionID: props.question.id,
						response: e.target.value,
						notes: '',
						score: score,
					}

					addSingleResponse(newResponse, userResponses, props.question, dispatch)
				}}
			/>
			{props.question.hasNotes ? <Notes question={props.question} userID={props.userID} /> : <div />}
		</div>
	)
}

export default LongResponse
