export function getRelevantQuestions(questionList, section) {
	let relQuestions = []

	for (let i = 0; i < questionList.length; i++) {
		if (questionList[i].section === section) {
			relQuestions.push(questionList[i])
		}
	}

	// Puts the questions in the correct order
	relQuestions.sort((a, b) => a.order - b.order)

	return relQuestions
}

export function getRelevantQuestionAnswers(questionAnswerList, id) {
	let relAnswers = []

	for (let i = 0; i < questionAnswerList.length; i++) {
		if (questionAnswerList[i].questionID === id) {
			relAnswers.push(questionAnswerList[i])
		}
	}

	return relAnswers
}

// Sums up score from all the questions
export function sumScore(responses, timedResponses, infoButtonScore) {
	let score = 0

	for (let i = 0; i < responses.length; i++) {
		score += responses[i].score
	}

	for (let i = 0; i < timedResponses.length; i++) {
		score += timedResponses[i].score
		if (timedResponses[i].fkScore !== undefined && timedResponses[i].fkScore !== null) {
			score += timedResponses[i].fkScore
		}
	}

	score += infoButtonScore

	return score
}

// Returns an array of all the scoring tags for a question
// ie "matchDate;anotherScoreTag;another" returns [matchDate, anotherScoreTag, another]
export function parseScoringTags(scoreString) {
	let tags = []

	if (scoreString === null || scoreString === undefined) {
		return tags
	}

	if (scoreString.length > 0) {
		tags = scoreString.split(';')
	}

	return tags
}

// Returns an array of the arguments in between the brackets
export function parseArguments(tag) {
	if (tag === undefined || tag === null) {
		return null
	}

	let args = ''
	let i = 0

	while (i < tag.length) {
		if (tag[i] === '(') {
			i++
			break
		}
		i++
	}

	while (i < tag.length && tag[i] !== ')') {
		args += tag[i]
		i++
	}

	return args.split(',')
}

// Searches the list of scoring tags for tag
export function findScoringTags(tags, tag) {
	for (let i = 0; i < tags.length; i++) {
		let currTag = ''
		for (let j = 0; j < tags[i].length; j++) {
			if (tags[i][j] !== '(') {
				currTag += tags[i][j]
			} else {
				break
			}
		}
		if (currTag === tag) {
			return i
		}
	}
	return -1
}

export function convertToTimeString(milli) {
	if (milli < 0) {
		milli = 0
	}

	let minutes = milli / 60000
	let seconds = (milli / 1000) % 60

	if (isNaN(minutes) || isNaN(seconds)) return '00:00'

	minutes = Math.floor(minutes)
	seconds = Math.floor(seconds)

	if (seconds < 10) {
		seconds = '0' + seconds.toString()
	} else {
		seconds = seconds.toString()
	}
	if (minutes < 10) {
		minutes = '0' + minutes.toString()
	} else {
		minutes = minutes.toString()
	}

	let timeString = minutes + ':' + seconds

	return timeString
}

// Checks to make sure there is a submitted response for each question of relQuestions
// Returns an array of the order of the questions that are missing
// If all questions are submitted then returns an empty array
export function checkSubmittedQuestions(userResponses, relQuestions) {
	const orders = []

	for (const question of relQuestions) {
		let found = 0

		for (const response of userResponses) {
			if (response.questionID === question.id && response.submitted) {
				found = 1
				break
			}
		}

		if (found === 0) {
			orders.push(question.order)
		}
	}

	return orders
}

export function getRelScoringTags(tags, question) {
	let relTags = []

	for (let tag of tags) {
		if (tag.questionID === question.id) {
			relTags.push(tag)
		}
	}

	return relTags
}

// Add tab functionality to text areas
export function onTabPress(ref, e) {
	e.preventDefault()
	const currValue = ref.current.value
	const start = e.target.selectionStart
	const end = e.target.selectionEnd
	ref.current.value = currValue.substring(0, start) + '   ' + currValue.substring(end)
	e.target.selectionStart = start + 3
	e.target.selectionEnd = start + 3
}
