import React, { useState } from 'react'
import NavButton from '../components/Button/NavButton'
import { Typography } from '@mui/material'
import PositionInfoButton from '../components/PositionInfoButton'
import PositionDetailsPopup from '../components/PositionDetailsPopup'
import { useSelector } from 'react-redux'
import { getRelevantQuestions } from '../functions/HelperFunctions'
import VoiceQuestion from '../questions/VoiceQuestion'
import HelpButton from '../components/HelpButton'

import './Pages.css'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'
import PageTitle from '../components/Heading/PageTitle'

function Voice(props) {
	const [showInfo, setShowInfo] = useState(false)
	let questions = getRelevantQuestions(
		useSelector((state) => state.questions),
		'Voice'
	)
	let userID = useSelector((state) => state.userID)
	const logo = useSelector((state) => state.logo)

	return (
		<PageContainer>
			<CardContainer style={{ paddingTop: 0 }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: 'calc(100% + 25px)',
						minHeight: 75,
					}}
				>
					<img src={logo} alt='' style={{ maxHeight: 100, maxWidth: 200, display: 'flex', justifyContent: 'start' }} />
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<PositionInfoButton setTrigger={setShowInfo} trigger={showInfo} />
						<HelpButton />
					</div>
				</div>

				<PageTitle style={{ marginBottom: 10 }}>As part of this application, we'd like to hear from you.</PageTitle>

				{questions.map((question) => {
					if (question.format === 'Voice') {
						return <VoiceQuestion question={question} userID={userID} key={question.id} />
					}
					return <div />
				})}

				<Typography style={{ fontSize: 16, color: 'grey', textAlign: 'start', marginTop: 30 }}>
					Note: You must allow microphone permission in your web browser to be able to record a message.
				</Typography>

				<div className='NavigationContainer'>
					<NavButton text='Back' onClick={() => props.prevPage()} saveOnPageChange />
					<NavButton text='Next' onClick={() => props.nextPage()} saveOnPageChange />
				</div>
			</CardContainer>
			<div style={{ display: 'flex', justifyContent: 'end' }}>
				{showInfo ? <PositionDetailsPopup setTrigger={setShowInfo} /> : <div />}
			</div>
		</PageContainer>
	)
}

export default Voice
