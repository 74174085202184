import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { loadTest } from '../functions/DatabaseFunctions'
import LoadingIcons from 'react-loading-icons'
import CardContainer from '../components/Containers/CardContainer'
import PageContainer from '../components/Containers/PageContainer'
import BlueButton from '../components/Button/BlueButton'
import ResumeTestInput from '../components/ResumeTestInput'

const styles = {
	logo2: {
		maxHeight: '150px',
		maxWidth: '300px',
	},
	center: {
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px',
	},
	item: {
		paddingTop: '25px',
		fontSize: '16px',
		textAlign: 'start',
		alignSelf: 'start',
	},
}

function Welcome(props) {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)

	const testID = useSelector((state) => state.testID)
	const logo = useSelector((state) => state.logo)

	return (
		<PageContainer>
			<CardContainer>
				<img src={logo} alt='' style={styles.logo2} />
				<Typography style={styles.item}>
					We are looking forward to seeing if this opportunity is a good fit with your skills. To set expectations, we will be
					asking you to dedicate some uninterrupted time to complete this exercise. You will be asked to provide some input
					regarding your experience and skills, along with completing a detailed case study. Once you are prepared to start,
					please do your best to complete this exercise in one shot.
				</Typography>
				<Typography style={styles.item}>Looking forward to hearing from you. When you are ready, click below.</Typography>
				<div style={{ marginTop: 25 }}>
					{loading ? (
						<LoadingIcons.Oval speed={1} stroke='lightblue' opacity={1} color='lightblue' />
					) : (
						<BlueButton
							data-cy='welcome-continue'
							onClick={() => {
								setLoading(true)
								loadTest(dispatch, testID).then(
									() => {
										props.nextPage()
									},
									() => {
										console.log('ERROR!')
									}
								)
							}}
						>
							Begin Test
						</BlueButton>
					)}
				</div>
				<ResumeTestInput />
			</CardContainer>
		</PageContainer>
	)
}

export default Welcome
