import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { changeUserResponses } from '../actions'

function addNotes(notes, userResponses, questionID, dispatch) {
	let newUserResponses = [...userResponses]

	// Search for existing user response for this question
	for (let i = 0; i < newUserResponses.length; i++) {
		if (newUserResponses[i].questionID === questionID) {
			// if found, update the response

			newUserResponses[i].notes = notes
			dispatch(changeUserResponses(newUserResponses))
			return
		}
	}

	newUserResponses.push({
		questionID,
		response: '',
		notes,
		score: 0,
	})
	dispatch(changeUserResponses(newUserResponses))
}

function Notes(props) {
	const dispatch = useDispatch()
	const userResponses = useSelector((state) => state.userResponses)

	const [defaultValue, setDefaultValue] = useState('')

	useEffect(() => {
		for (let i = 0; i < userResponses.length; i++) {
			if (userResponses[i].questionID === props.question.id) {
				setDefaultValue(userResponses[i].notes)
				return
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div style={{ paddingBottom: 20 }}>
			<Typography style={{ fontSize: 12, textAlign: 'start' }}> Reason for response: </Typography>
			<textarea
				style={{ height: 40, width: '100%', resize: 'none', fontSize: 12 }}
				defaultValue={defaultValue}
				onBlur={(e) => {
					addNotes(e.target.value, userResponses, props.question.id, dispatch)
				}}
			/>
		</div>
	)
}

export default Notes
