const changeTestTimes = (state = [], action) => {
	switch (action.type) {
		case 'CHANGE_TEST_TIMES':
			return action.payload
		case 'START_NEW_TEST_TIME':
			const currentTime = action.payload
			return [...state, { startTime: currentTime, endTime: currentTime }]
		case 'START_AND_LOAD_TEST_TIMES':
			const { newTime, loadedTimes } = action.payload

			return [...loadedTimes, { startTime: newTime, endTime: newTime }]
		case 'UPDATE_TEST_END_TIME':
			const { endTime } = action.payload
			const newTestTimes = [...state]

			if (newTestTimes.length === 0) {
				return state
			}
			newTestTimes[newTestTimes.length - 1].endTime = endTime
			return newTestTimes

		default:
			return state
	}
}

export default changeTestTimes
