import React, { useEffect, useState } from 'react'
import NavButton from '../components/Button/NavButton'
import { Typography, Button, Divider, Stepper, StepButton, Step, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import MultipleChoice from '../questions/MultipleChoice'
import ShortResponse from '../questions/ShortResponse'
import MultipleCheckbox from '../questions/MultipleCheckbox'
import TimedMultipleChoice from '../questions/TimedMultipleChoice'
import LongResponse from '../questions/LongResponse'
import Memo from '../questions/Memo'
import { checkSubmittedQuestions, getRelevantQuestions } from '../functions/HelperFunctions'
import PositionInfoButton from '../components/PositionInfoButton'
import PositionDetailsPopup from '../components/PositionDetailsPopup'
import { changeCSQuestionIndex, changeStartedCS } from '../actions'

import DownloadIcon from '@mui/icons-material/FileDownloadOutlined'
import HelpButton from '../components/HelpButton'

import { Storage } from 'aws-amplify'

import './Pages.css'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'
import BlueButton from '../components/Button/BlueButton'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

function getQuestionType(question, questionAnswers, userID) {
	if (question === null || question === undefined) {
		return <div />
	}

	if (question.format === 'MultipleChoice') {
		return <MultipleChoice question={question} questionAnswers={questionAnswers} userID={userID} key={question.id} />
	} else if (question.format === 'ShortResponse') {
		return <ShortResponse question={question} userID={userID} key={question.id} />
	} else if (question.format === 'Checkbox') {
		return <MultipleCheckbox question={question} questionAnswers={questionAnswers} userID={userID} key={question.id} />
	} else if (question.format === 'LongResponse') {
		return <LongResponse question={question} userID={userID} key={question.questionText} />
	} else if (question.format === 'TimedMultipleChoice') {
		return <TimedMultipleChoice question={question} questionAnswers={questionAnswers} userID={userID} key={question.id} />
	} else if (question.format === 'Memo') {
		return <Memo question={question} userID={userID} />
	} else {
		return <div />
	}
}

function CaseStudy(props) {
	const [showInfo, setShowInfo] = useState(false)

	// const [contButtonDisabled, setContButtonDisabled] = useState(false)

	const questions = getRelevantQuestions(
		useSelector((state) => state.questions),
		'CaseStudy'
	)
	const description = getRelevantQuestions(
		useSelector((state) => state.questions),
		'CaseStudyDescription'
	)
	const instructions = getRelevantQuestions(
		useSelector((state) => state.questions),
		'CaseStudyInstructions'
	)
	const questionAnswers = useSelector((state) => state.questionAnswers)
	const userID = useSelector((state) => state.id)
	const timedResponses = useSelector((state) => state.timedUserResponses)
	const dispatch = useDispatch()

	const startedCS = useSelector((state) => state.startedCS)
	const currIndex = useSelector((state) => state.cSQuestionIndex)

	const caseStudyFiles = useSelector((state) => state.caseStudyFiles)
	const logo = useSelector((state) => state.logo)

	const contButtonDisabled = checkSubmittedQuestions(timedResponses, questions).length > 0

	// Handles keeping track of Case Study Times. Adds a new TimeBlock when the case study is rendered.
	// When they leave the page, it updates the end time of the last time block.
	useEffect(() => {
		dispatch({ type: 'START_CASE_STUDY_TIMER', payload: { startTime: new Date().getTime() } })

		return () => {
			dispatch({ type: 'END_CASE_STUDY_TIMER', payload: { endTime: new Date().getTime() } })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Updates the end time of the case study whenever timedResponses is changed.
	// It is important that the useEffects are trigged in this order, or else it
	// would update the end time of the previous time block in the array.
	useEffect(() => {
		dispatch({ type: 'END_CASE_STUDY_TIMER', payload: { endTime: new Date().getTime() } })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timedResponses])

	return (
		<PageContainer>
			<CardContainer style={{ paddingTop: 0 }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: 'calc(100% + 25px)',
						minHeight: 75,
					}}
				>
					<img src={logo} alt='' style={{ maxHeight: 100, maxWidth: 200, display: 'flex', justifyContent: 'start' }} />
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<PositionInfoButton setTrigger={setShowInfo} trigger={showInfo} />
						<HelpButton />
					</div>
				</div>

				<Typography style={{ fontSize: 18, textAlign: 'start', fontWeight: 'bold', alignSelf: 'start' }}>
					Now we'd like you to complete a short assignment
				</Typography>

				<Typography style={{ fontSize: 16, textAlign: 'start', paddingTop: 15, whiteSpace: 'pre-line' }}>
					{description[0].questionText}
				</Typography>

				<Divider sx={{ width: '100%', margin: '20px 0' }} />

				<Typography style={{ fontSize: 18, textAlign: 'start', fontWeight: 'bold', alignSelf: 'start' }}>Instructions</Typography>

				<Typography style={{ fontSize: 16, textAlign: 'start', paddingTop: 15, whiteSpace: 'pre-line' }}>
					{instructions[0].questionText}
				</Typography>

				<Divider sx={{ width: '100%', margin: '20px 0' }} />

				<Typography style={{ fontSize: 18, textAlign: 'start', fontWeight: 'bold', alignSelf: 'start', marginBottom: 20 }}>
					Download Files
				</Typography>

				<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', gap: 20 }}>
					{caseStudyFiles.map((item, index) => {
						return (
							<BlueButton
								key={index}
								onClick={async () => {
									try {
										const result = await Storage.get(item.file, {
											level: 'public',
											download: true,
										})
										const url = URL.createObjectURL(result.Body)

										const a = document.createElement('a')
										a.href = url
										a.target = '_blank'
										a.click()
									} catch (e) {
										alert('There was an error downloading the file.')
										console.error(e)
									}
								}}
							>
								<DownloadIcon style={{ paddingRight: 7 }} />
								<Typography style={{ paddingRight: 7, fontSize: 16 }}>{item.name}</Typography>
							</BlueButton>
						)
					})}
				</div>

				<Divider sx={{ width: '100%', margin: '30px 0 15px' }} />

				{startedCS ? (
					<div style={{ width: '100%' }}>
						<Box display='flex' justifyContent='space-between'>
							<Button
								size='small'
								data-cy='casestudy-backward'
								sx={{ fontSize: 14, textTransform: 'none', color: '#0066b2', width: 100 }}
								disabled={currIndex === 0}
								onClick={() => {
									if (currIndex !== 0) {
										// setCurrQuestion(getQuestionType(questions[currIndex - 1], questionAnswers, userID, updateEndTime))
										dispatch(changeCSQuestionIndex(currIndex - 1))
									}
								}}
							>
								<KeyboardArrowLeft />
								Previous
							</Button>
							<Stepper nonLinear activeStep={currIndex}>
								{questions.map((question, index) => (
									<Step
										key={question.id}
										completed={timedResponses.find((response) => response.questionID === question.id)?.submitted}
									>
										<StepButton
											disableRipple
											sx={{
												'& .MuiSvgIcon-root.Mui-completed': {
													color: '#9e9e9e',
												},
												'& .MuiSvgIcon-root.Mui-active': { color: '#0066b2', opacity: 1 },
											}}
											onClick={() => {
												dispatch(changeCSQuestionIndex(index))
												// setCurrQuestion(getQuestionType(questions[index], questionAnswers, userID, updateEndTime))
											}}
										/>
									</Step>
								))}
							</Stepper>
							<Button
								size='small'
								data-cy='casestudy-forward'
								sx={{ fontSize: 14, textTransform: 'none', color: '#0066b2', width: 100 }}
								disabled={currIndex === questions.length - 1}
								onClick={() => {
									if (currIndex !== questions.length - 1) {
										dispatch(changeCSQuestionIndex(currIndex + 1))
									}
								}}
							>
								Next
								<KeyboardArrowRight />
							</Button>
						</Box>
						{getQuestionType(questions[currIndex], questionAnswers, userID)}
					</div>
				) : (
					<div
						style={{
							minHeight: 300,
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<BlueButton
							onClick={() => {
								dispatch(changeStartedCS(true))
							}}
							// style={{ textTransform: 'none', fontSize: 16 }}
							// style={{ background: '#0066b2', color: 'white', textTransform: 'none', height: 45, fontSize: 16, padding: 15 }}
						>
							Click here to begin
						</BlueButton>
					</div>
				)}
				<div className='NavigationContainer'>
					<NavButton text='Back' onClick={() => props.prevPage()} saveOnPageChange />
					<NavButton
						text='Next'
						// style={{ background: contButtonDisabled ? '#b0b0b0' : '#0066b2' }}
						style={contButtonDisabled ? { background: '#b0b0b0', border: '2px solid #b0b0b0' } : {}}
						onClick={() => {
							const unsubmittedQuestions = checkSubmittedQuestions(timedResponses, questions)

							if (unsubmittedQuestions.length > 0) {
								alert(
									'The following questions have not been submitted: \n\n' +
										unsubmittedQuestions
											.map((questionNumber) => {
												return 'Question ' + questionNumber + '\n'
											})
											.join('') +
										'\nPlease click the submit button at the bottom of a question to submit a response. '
								)
							} else {
								props.nextPage()
							}
						}}
						saveOnPageChange
					/>
				</div>
			</CardContainer>
			<div style={{ display: 'flex', justifyContent: 'end' }}>
				{showInfo ? <PositionDetailsPopup setTrigger={setShowInfo} /> : <div />}
			</div>
		</PageContainer>
	)
}

export default CaseStudy
