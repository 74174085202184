import React from 'react'
import { useState } from 'react'
import { Typography, Modal, Button } from '@mui/material'
import CardContainer from './Containers/CardContainer'
import { useContext } from 'react'
import { UnfinishedAttemptContext } from '../contexts/UnfinishedAttemptContext'
import { useSelector } from 'react-redux'
import BlueButton from './Button/BlueButton'

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

function PersonalAccessCodeModal() {
	const { hasViewedCodeModal, setHasViewedCodeModal } = useContext(UnfinishedAttemptContext)
	const [modalOpen, setModalOpen] = useState(!hasViewedCodeModal)
	const [hasCopied, setHasCopied] = useState(false)

	const personalAccessCode = useSelector((state) => state.personalAccessCode)

	return (
		<Modal open={modalOpen}>
			<CardContainer style={{ position: 'fixed', width: '400px', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
				<Typography style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>Your Access Code</Typography>
				<Typography style={{ alignSelf: 'start', paddingTop: 20 }}>
					In case of technical difficulties or other issues, we are providing you with a personal access code. You can return to
					the test if you lose connection by entering your general access code on the landing page, and then entering your
					personal access code on the page after. Please copy and store the following code:
				</Typography>
				<br />
				<span style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
					<span style={{ fontWeight: 'bold', fontSize: 14 }} data-cy='personal-access-code'>
						{personalAccessCode}
					</span>
					<Button
						style={{ height: 40, width: 60 }}
						onClick={() => {
							navigator.clipboard.writeText(personalAccessCode)
							setHasCopied(true)
						}}
					>
						{hasCopied ? (
							<span style={{ color: '#0066b2', textTransform: 'none' }}>Copied</span>
						) : (
							<ContentCopyIcon style={{ color: '#0066b2' }} />
						)}
					</Button>
				</span>
				<Typography style={{ alignSelf: 'start', paddingTop: 20 }}>
					Your personal details are not stored until you submit your application. You can view your personal access code again by
					clicking the
					<span>
						<HelpOutlineOutlinedIcon style={{ color: 'grey', paddingLeft: 2, paddingRight: 4, marginBottom: -8 }} />
					</span>
					icon at the top of most pages.
				</Typography>
				<BlueButton
					style={{ marginTop: 20 }}
					onClick={() => {
						setModalOpen(false)
						setHasViewedCodeModal(true)
					}}
				>
					I have copied my code
				</BlueButton>
			</CardContainer>
		</Modal>
	)
}

export default PersonalAccessCodeModal
