import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { convertToTimeString } from "../functions/HelperFunctions";

function Timer(props) {

    const [time, setTime] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date().getTime() - props.startTime)
        }, 1000) // Occurs every 1 second (1000 ms)
        return () => {
            clearInterval(interval) // Stops from ticking when the component unmounts
        }
    }, [props.startTime])

    return (
        <Button style={{color: 'black', fontSize: 20}} disabled>
            {convertToTimeString(time)}
        </Button>
    )

}

export default Timer