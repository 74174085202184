import { changeScoringTagResps } from '../actions'

// Adds the new scoring tag responses to the store
// If a response exists in the store already for a tag, it replaces it with the new response
export function addScoringTagResponses(responses, newResponses, dispatch) {
	const newRespsIDs = newResponses.map((resp) => resp.scoringTagID)
	let allNewResps = []

	for (const response of responses) {
		// Only add responses from old list that are not being replaced
		if (!newRespsIDs.includes(response.scoringTagID)) {
			allNewResps.push(response)
		}
	}

	// Add all the new responses
	for (const response of newResponses) {
		allNewResps.push(response)
	}

	dispatch(changeScoringTagResps(allNewResps))
}

export function deleteScoringTagResponse(responses, tag, dispatch) {
	let newResponses = [...responses]

	for (let i = 0; i < newResponses.length; i++) {
		if (newResponses[i].scoringTagID === tag.id) {
			newResponses.splice(i, 1)

			dispatch(changeScoringTagResps(newResponses))

			return
		}
	}
}

/*  ###############################################################################################################
    ###############################################################################################################
    ###############################################################################################################
*/

export function scoreShortResponseTags(text, tags, tagResponses, dispatch) {
	let score = 0
	let tagRespScore = 0
	let newResponses = []

	// Process Scoring Tags
	for (const tag of tags) {
		tagRespScore = 0

		switch (tag.tag) {
			case 'MatchDate':
				if (matchDate(text)) {
					tagRespScore = 1
				}
				break
			case 'TargetYears':
				tagRespScore = scoreTargetYears(text, parseInt(tag.data))
				break

			default:
		}

		score += tagRespScore
		const newResponse = { scoringTagID: tag.id, score: tagRespScore }
		newResponses.push(newResponse)
	}

	addScoringTagResponses(tagResponses, newResponses, dispatch)

	return score
}

export function scoreTimedMultipleChoiceTags(text, tags, tagResponses, multiChoiceScore, dispatch) {
	let score = multiChoiceScore
	let tagRespScore = 0
	let newResponses = []

	for (const tag of tags) {
		tagRespScore = 0

		switch (tag.tag) {
			case 'FleschKincaid':
				tagRespScore = scoreFK(text)
				break
			case 'HitWords':
				tagRespScore = scoreHitWords(text, tag)
				break
			case 'CaseStudyTMCMapRE': // If the response is being mapped to Real Estate Knowledge
				tagRespScore = multiChoiceScore
				score -= multiChoiceScore // Don't want to double dip in the base response score
				break
			default:
		}

		score += tagRespScore
		const newResponse = { scoringTagID: tag.id, score: tagRespScore }
		newResponses.push(newResponse)
	}
	addScoringTagResponses(tagResponses, newResponses, dispatch)

	return score
}

export function scoreMemoTags(text, tags, tagResponses, dispatch) {
	let score = 0
	let newResponses = []
	let tagRespScore

	for (const tag of tags) {
		tagRespScore = 0

		switch (tag.tag) {
			case 'FleschKincaid':
				tagRespScore = scoreFK(text)
				break
			case 'HitWords':
				tagRespScore = scoreHitWords(text, tag)
				break
			default:
		}

		score += tagRespScore
		const newResponse = { scoringTagID: tag.id, score: tagRespScore }
		newResponses.push(newResponse)
	}
	addScoringTagResponses(tagResponses, newResponses, dispatch)

	return score
}

export function scoreLongResponseTags(text, tags, tagResponses, dispatch) {
	let score = 0
	let tagRespScore = 0
	let newResponses = []

	for (const tag of tags) {
		tagRespScore = 0

		switch (tag.tag) {
			case 'FleschKincaid':
				tagRespScore = scoreFK(text)
				break

			case 'ParagraphBreaks':
				tagRespScore = scoreParagraphBreaks(text)
				break

			case 'HitWords':
				tagRespScore = scoreHitWords(text, tag)
				break

			default:
		}

		score += tagRespScore
		const newResponse = { scoringTagID: tag.id, score: tagRespScore }
		newResponses.push(newResponse)
	}

	addScoringTagResponses(tagResponses, newResponses, dispatch)

	return score
}

export function scoreDropdownTags(tags, tagResponses, dispatch) {
	let score = 0
	let newResponses = []

	for (let tag of tags) {
		let tagRespScore = 0

		switch (tag.tag) {
			case 'AccessDropdown':
				tagRespScore = tag.maxScore
				break
			default:
		}

		score += tagRespScore
		const newResponse = { scoringTagID: tag.id, score: tagRespScore }
		newResponses.push(newResponse)
	}

	addScoringTagResponses(tagResponses, newResponses, dispatch)

	return score
}

export function scoreMultipleChoiceTags(response, tags, tagResponses, dispatch) {
	const newResponses = []

	for (const tag of tags) {
		switch (tag.tag) {
			case 'MatchResponse':
				if (tag.data === response) {
					const newResponse = { scoringTagID: tag.id, score: tag.maxScore }
					newResponses.push(newResponse)
				}
				break
			default:
		}
	}

	addScoringTagResponses(tagResponses, newResponses, dispatch)
}

export function scoreMultipleCheckboxTags(response, tags, tagResponses, dispatch) {
	let newResponses = []

	// Process scoring tags
	for (const tag of tags) {
		switch (tag.tag) {
			case 'MatchResponse':
				if (tag.data === response) {
					const newResponse = { scoringTagID: tag.id, score: tag.maxScore }
					newResponses.push(newResponse)
				}
				break
			default:
		}
	}

	addScoringTagResponses(tagResponses, newResponses, dispatch)
}

/*  ###############################################################################################################
    ###############################################################################################################
    ###############################################################################################################
*/

export function scoreParagraphBreaks(text) {
	if (countParagraphs(text) === 2) {
		return 0.5
	}
	return 0
}

export function countParagraphs(text) {
	if (!text) return 0

	let numParagraphs = 1
	let readNonLB = 0 // Non line break character

	for (let i = 0; i < text.length; i++) {
		if (text[i] === '\n' && readNonLB === 1) {
			numParagraphs++
			readNonLB = 0
		} else if (text[i] !== '\n' && text[i] !== '\t' && text[i] !== ' ') {
			readNonLB = 1
		}
	}

	if (readNonLB === 0) {
		numParagraphs--
	}

	return numParagraphs
}

/*  ###############################################################################################################
    ###############################################################################################################
    ###############################################################################################################
*/

export function scoreFK(text) {
	if (text === undefined || text === null || text.length <= 0) {
		return 0
	}

	const numWords = countWords(text)
	const numSentences = countSentences(text)
	const numSyllables = countSyllablesOfText(text)

	if (numSentences === 0 || numWords === 0) {
		return 0
	}

	const score = 206.835 - (1.015 * numWords) / numSentences - (84.6 * numSyllables) / numWords

	if (score >= 0 && score <= 50) {
		return 10
	} else if (score > 50 && score <= 80) {
		return 5
	} else if (score <= 90) {
		return 1
	} else {
		return 0
	}
}

function countWords(text) {
	let words = text.split(/[\s]+/)

	if (words) {
		return words.length
	} else {
		return 0
	}
}

function countSentences(text) {
	let sentences = text.split(/[.!?]/)

	if (sentences) {
		return sentences.length - 1
	} else {
		return 0
	}
}

function countSyllablesOfText(text) {
	let words = text.split(/[\s]+/)
	let numSyllables = 0

	for (let i = 0; i < words.length; i++) {
		words[i] = words[i].replace(/[.!?,()'"]+/, '')
		numSyllables += countSyllablesOfWord(words[i])
	}

	return numSyllables
}

// Function taken from https://stackoverflow.com/questions/5686483/how-to-compute-number-of-syllables-in-a-word-in-javascript
function countSyllablesOfWord(word) {
	if (word === null || word === undefined || word.length === 0) return 0

	word = word.toLowerCase()

	if (word.length <= 3) {
		return 1
	}
	word = word.replace(/(?:[^laeiouy]es|ed|[^laeiouy]e)$/, '')
	word = word.replace(/^y/, '')

	let syl = word.match(/[aeiouy]{1,2}/g)

	if (syl) {
		return syl.length
	} else {
		return 0
	}
}

/*  ###############################################################################################################
    ###############################################################################################################
    ###############################################################################################################
*/

export function scoreHitWords(text, tag) {
	if (!text || !tag) return 0

	let score = 0
	const words = tag.data.split(',')

	for (const word of words) {
		if (text.toLowerCase().includes(word.toLowerCase())) {
			score += 0.5
		}
	}

	return score
}

/*  ###############################################################################################################
    ###############################################################################################################
    ###############################################################################################################
*/

export function matchDate(dateString) {
	if (!dateString || dateString.length !== 7) {
		return false
	}

	for (let i = 0; i <= 3; i++) {
		if (dateString[i] < '0' || dateString[i] > '9') {
			return false
		}
	}

	if (dateString[4] !== '-') {
		return false
	}

	for (let i = 5; i <= 6; i++) {
		if (dateString[i] < '0' || dateString[i] > '9') {
			return false
		}
	}

	return true
}

/*  ###############################################################################################################
    ###############################################################################################################
    ###############################################################################################################
*/

export function scorePrivacyPolicy(startTime, endTime) {
	if (endTime - startTime > 120000) {
		return 3
	}

	return 0
}

/*  ###############################################################################################################
    ###############################################################################################################
    ###############################################################################################################
*/

export function scoreTargetYears(input, target) {
	if (!input || input.length === 0) return 0

	const years = parseInt(input)

	if (isNaN(years) || isNaN(target)) return 0

	const score = 3 - Math.abs(target - years)

	if (score < 0) return 0
	return score
}

/*  ###############################################################################################################
    ###############################################################################################################
    ###############################################################################################################
*/
