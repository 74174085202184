import { Typography, Button, Card } from '@mui/material'
import React from 'react'
import BlueButton from './Button/BlueButton'
import PageTitle from './Heading/PageTitle'

function ConfirmSubmit(props) {
	return (
		<div className='popup-container'>
			<Card
				style={{
					position: 'fixed',
					width: 450,
					overflow: 'auto',
					// height: 150,
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					background: 'white',
					padding: 30,
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<PageTitle style={{ marginBottom: 5 }}>Confirm Submission</PageTitle>
				<Typography style={{ fontSize: 16, textAlign: 'left' }}>{props.text}</Typography>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', marginTop: 30, gap: 20 }}>
					<Button onClick={props.onNo} variant='outlined' style={{ textTransform: 'none', fontSize: 16 }}>
						Cancel
					</Button>
					<BlueButton onClick={props.onYes} data-cy='confirm-submit'>
						Confirm
					</BlueButton>
				</div>
			</Card>
		</div>
	)
}

export default ConfirmSubmit
