const changeTimedUserResponses = (state = [], action) => {

    switch(action.type) {
        case 'CHANGETIMEDUSERRESPONSES':
            return action.payload;

        case 'UPDATE_TIMED_RESPONSE':
            const { updates, questionID: questionToUpdateID } = action.payload;
            return state.map((response) => {
                if (response.questionID !== questionToUpdateID) {
                    return response
                }

                if (!response.timeTaken || response.timeTaken.length === 0) {
                    console.error('Error: This response does not seem to be initiated yet.');
                    return response;
                }

                const newResponse = { ...response };
                newResponse.timeTaken[newResponse.timeTaken.length - 1].endTime = new Date().getTime();

                for (const key in updates) {
                    newResponse[key] = updates[key];
                }

                return newResponse;
            });

        case 'START_OR_UPDATE_TIME':
            const { currTime, questionID: questionToStartID } = action.payload;

            const newTimedResponses = [...state]
            const response = newTimedResponses.find((response) => response.questionID === questionToStartID)

            if (response) {
                response.timeTaken.push({ startTime: currTime, endTime: currTime })
            } else {
                newTimedResponses.push({
                    questionID: questionToStartID,
                    response: '',
                    notes: '',
                    score: 0,
                    submitted: false,
                    timeTaken: [{ startTime: currTime, endTime: currTime }],
                })
            }

            return newTimedResponses;

            
        default:
            return state;
    }
}

export default changeTimedUserResponses;