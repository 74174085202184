import React from 'react'
import { Typography } from '@mui/material'
// import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded'
import { useSelector } from 'react-redux'
import NavButton from '../components/Button/NavButton'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'
import PageTitle from '../components/Heading/PageTitle'

function Thanks(props) {
	const logo = useSelector((state) => state.logo)
	const questions = useSelector((state) => state.selfIdentificationQuestions)

	return (
		<PageContainer>
			<CardContainer style={{ paddingTop: 0 }}>
				<div style={{ minHeight: 75, display: 'flex' }}>
					<img src={logo} alt='' style={{ maxWidth: 200, maxHeight: 100, display: 'flex', justifyContent: 'start' }} />
				</div>

				<PageTitle style={{ alignSelf: 'center' }}>Thank you for your interest!</PageTitle>

				<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 20 }}>
					You have successfully completed your application and your responses have been submitted to our team.
				</Typography>

				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<CheckCircleIcon style={{ color: '#32A846', fontSize: 50 }} />
				</div>

				{questions && questions.length > 0 && (
					<>
						<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 20, paddingTop: 40 }}>
							In an effort to improve on our Diversity, Equity, and Inclusion initiatives, we are interested in understanding
							how individuals interested in working for our company self-identify. This information will be anonymized,
							separated from your application results, and is completely voluntary. Should you choose to participate, please
							hit the “Next” button below.
						</Typography>

						<div className='NavigationContainer'>
							<NavButton text='Next' onClick={() => props.nextPage()} />
						</div>
					</>
				)}
			</CardContainer>
		</PageContainer>
	)
}

export default Thanks
