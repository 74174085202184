import React from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { useSelector } from 'react-redux'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'

function SuccessPage() {
	const logo = useSelector((state) => state.logo)

	return (
		<PageContainer>
			<CardContainer>
				<div style={{ minHeight: 75, display: 'flex', alignItems: 'center' }}>
					<img src={logo} alt='' style={{ maxWidth: 200, maxHeight: 100, display: 'flex', justifyContent: 'start' }} />
				</div>
				<p style={{ fontSize: 18, marginTop: 20 }}>Your responses have been successfully submitted!</p>
				<CheckCircleIcon style={{ color: '#32A846', fontSize: 50, marginTop: 10 }} />
			</CardContainer>
		</PageContainer>
	)
}

export default SuccessPage
