import React, { useState } from 'react'
import { TextField, Typography } from '@mui/material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import BTRLogo from '../images/BTRHireClientTransparent.png'
import { useDispatch } from 'react-redux'
import { changeAccessCode, changeAuthenticated, changeTestID } from '../actions'
import { loadCaseStudyFiles, loadLogo, loadSelfIdentificationQuestions, verifyAccessCode } from '../functions/DatabaseFunctions'

import { CircularProgress } from '@mui/material'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'
import BlueButton from '../components/Button/BlueButton'

function LandingPage(props) {
	const [code, setCode] = useState('')
	const [codeError, setCodeError] = useState(false)
	const [helperMsg, setHelperMsg] = useState('Enter Access Code')
	const [loading, setLoading] = useState(false)
	const dispatch = useDispatch()

	return (
		<PageContainer>
			<CardContainer width={500}>
				<img src={BTRLogo} alt='' style={{ width: 350 }} />

				<Typography style={{ margin: '20px 20px 30px 20px', fontSize: 16 }}>
					Welcome to the BTR Hire testing portal. Please enter your code below to access the test.
				</Typography>

				<TextField
					variant='outlined'
					autoComplete='off'
					FormHelperTextProps={{ style: { textAlign: 'center', fontSize: 16, color: codeError ? 'red' : 'black' } }}
					inputProps={{ style: { textAlign: 'center' } }}
					size='small'
					helperText={helperMsg}
					error={codeError}
					value={code}
					style={{ color: 'black' }}
					onChange={(e) => {
						setCode(e.target.value)
						if (codeError === true) {
							setCodeError(false)
							setHelperMsg('Enter Access Code')
						}
					}}
				/>

				<BlueButton
					style={{ marginTop: '20px', width: 100, height: 40 }}
					onClick={async () => {
						setLoading(true)

						const result = await verifyAccessCode(code)

						if (result === 'LIMIT') {
							setHelperMsg('The maximum number of applications for this test has been reached.')
							setCodeError(true)
						} else if (result === 'CLOSED') {
							setHelperMsg('This status code is no longer available.')
							setCodeError(true)
						} else if (result !== '') {
							dispatch(changeAuthenticated(true))
							dispatch(changeTestID(result))
							dispatch(changeAccessCode(code))
							await loadLogo(dispatch, result)
							await loadCaseStudyFiles(dispatch, result)
							await loadSelfIdentificationQuestions(dispatch, result)
							props.nextPage()
							return
						} else {
							setHelperMsg('Invalid code.')
							setCodeError(true)
						}

						setLoading(false)
					}}
				>
					{loading ? <CircularProgress style={{ height: 22, width: 22, color: 'white' }} /> : <ArrowRightAltIcon />}
				</BlueButton>
			</CardContainer>
		</PageContainer>
	)
}

export default LandingPage
