import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getRelScoringTags, onTabPress } from '../functions/HelperFunctions'

import ClockIcon from '@mui/icons-material/AccessTimeOutlined'
import Notes from './Notes'
import { Button, Typography } from '@mui/material'

import ConfirmSubmit from '../components/ConfirmSubmit'
import { useRef } from 'react'
import { scoreLongResponseTags } from '../functions/ScoringTagFunctions'
import BlueButton from '../components/Button/BlueButton'
import { calculateTotalTimeFromTimeBlocks } from '../functions/Utils'

function convertToTimeString(milli) {
	if (milli < 0) {
		milli *= -1
	}

	let minutes = milli / 60000
	let seconds = (milli / 1000) % 60

	minutes = Math.floor(minutes)
	seconds = Math.floor(seconds)

	if (seconds < 10) {
		seconds = '0' + seconds.toString()
	} else {
		seconds = seconds.toString()
	}
	if (minutes < 10) {
		minutes = '0' + minutes.toString()
	} else {
		minutes = minutes.toString()
	}

	let timeString = minutes + ':' + seconds

	return timeString
}

function calculateInitialTimeRemaining(response, timeAllowed) {
	if (!response || !response.timeTaken) {
		return timeAllowed
	}

	if (response.submitted) {
		return timeAllowed - calculateTotalTimeFromTimeBlocks(response.timeTaken)
	}

	return timeAllowed - new Date().getTime() + response.timeTaken[0].startTime
}

// Lets say a user leaves a test. We do not want the timer to tick down while the test is closed.
// So, we calculate the new start time as the current time minus the time that they have taken.
function calculateStartTime(response) {
	const currTime = new Date().getTime()

	if (!response || !response.timeTaken) return currTime

	return currTime - calculateTotalTimeFromTimeBlocks(response.timeTaken)
}

function TimedLongResponse(props) {
	const timedUserResponses = useSelector((state) => state.timedUserResponses)
	const tags = getRelScoringTags(
		useSelector((state) => state.scoringTags),
		props.question
	)
	const tagResponses = useSelector((state) => state.scoringTagResps)
	const dispatch = useDispatch()

	const timeAllowed = parseInt(props.question.helperText) + 500

	const existingResponse = timedUserResponses.find((response) => response.questionID === props.question.id)
	const startTime = calculateStartTime(existingResponse)
	const defaultValue = existingResponse ? existingResponse.response : ''
	const [submitted, setSubmitted] = useState(existingResponse ? existingResponse.submitted : false)

	const [timeRemaining, setTimeRemaining] = useState(calculateInitialTimeRemaining(existingResponse, timeAllowed))

	const timeString = convertToTimeString(timeRemaining)

	const timerOver = timeRemaining <= 0
	const [showConfirm, setShowConfirm] = useState(false)

	const ref = useRef()

	// Creates a response with the start time if one does not already exist
	// If a response already exists, updates the state with the existing values
	useEffect(() => {
		dispatch({ type: 'START_OR_UPDATE_TIME', payload: { currTime: new Date().getTime(), questionID: props.question.id } })

		return () => {
			if (!submitted) dispatch({ type: 'UPDATE_TIMED_RESPONSE', payload: { updates: {}, questionID: props.question.id } })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// Starts timer ticking if they have not submitted the question
	useEffect(() => {
		if (submitted) return

		updateTimer()
		const interval = setInterval(() => {
			updateTimer()
		}, 500)

		// Updates the time remaining every 5 seconds
		const updateEndTimeInterval = setInterval(() => {
			dispatch({ type: 'UPDATE_TIMED_RESPONSE', payload: { updates: {}, questionID: props.question.id } })
		}, 5000)

		return () => {
			clearInterval(interval) // Stops from ticking when the component unmounts
			clearInterval(updateEndTimeInterval)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submitted])

	function updateTimer() {
		const currTime = new Date().getTime()
		setTimeRemaining(timeAllowed - currTime + startTime)
	}

	// When a user confirms that they want to submit the question
	function onSubmitConfirm() {
		dispatch({ type: 'UPDATE_TIMED_RESPONSE', payload: { updates: { submitted: true }, questionID: props.question.id } })
		// updateTimedResponse({ submitted: true }, timedUserResponses, props.question, dispatch)
		setSubmitted(true)
		setShowConfirm(false)
	}

	function onSubmitCancel() {
		setShowConfirm(false)
	}

	function updateResponse(e) {
		const score = scoreLongResponseTags(e.target.value, tags, tagResponses, dispatch)

		dispatch({
			type: 'UPDATE_TIMED_RESPONSE',
			payload: { updates: { response: e.target.value, score }, questionID: props.question.id },
		})
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
			<div style={{ display: 'flex', justifyContent: 'start' }}>
				<ClockIcon style={{ color: timerOver ? 'red' : 'black', paddingTop: 11, size: 20 }} />
				<Button style={{ fontSize: 20, color: timerOver ? 'red' : 'black' }} disabled>
					{timeString}
				</Button>
			</div>

			<Typography style={{ fontSize: 16, textAlign: 'start', margin: '15px 0 20px' }}>{props.question.questionText}</Typography>

			<textarea
				style={{ height: 200, width: '100%', resize: 'none', paddingBottom: 15 }}
				disabled={submitted}
				defaultValue={defaultValue}
				maxLength='4000'
				ref={ref}
				onKeyDown={(e) => {
					if (e.key === 'Tab') {
						onTabPress(ref, e)
					} else if (e.key === '.' || e.key === '?' || e.key === '!') {
						updateResponse(e)
					}
				}}
				onBlur={(e) => {
					updateResponse(e)
				}}
			/>
			{props.question.hasNotes ? <Notes question={props.question} userID={props.userID} /> : <div />}

			<div style={{ marginTop: 20 }}>
				<BlueButton disabled={submitted} onClick={() => setShowConfirm(true)}>
					Submit
				</BlueButton>
			</div>

			{showConfirm && (
				<ConfirmSubmit
					onYes={onSubmitConfirm}
					onNo={onSubmitCancel}
					text={'Are you sure you want to submit? You will not be able to edit your answer after submitting.'}
				/>
			)}
		</div>
	)
}

export default TimedLongResponse
