import React from 'react'
import { Typography, FormControlLabel, Checkbox, FormGroup } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { changeUserResponses } from '../actions'
import { getRelevantQuestionAnswers, getRelScoringTags } from '../functions/HelperFunctions'
import Notes from './Notes'
import { scoreMultipleCheckboxTags, deleteScoringTagResponse } from '../functions/ScoringTagFunctions'
import { deleteResponseByAnswerText } from '../functions/UserResponseFunctions'

function findQuestionAnswerResponse(userResponses, questionAnswer, question) {
	for (const response of userResponses) {
		if (response.questionID === question.id && response.response === questionAnswer.answerText) {
			return true
		}
	}

	return false
}

function MultipleCheckbox(props) {
	const dispatch = useDispatch()
	const userResponses = useSelector((state) => state.userResponses)
	const questionAnswers = getRelevantQuestionAnswers(props.questionAnswers, props.question.id)
	const tags = getRelScoringTags(
		useSelector((state) => state.scoringTags),
		props.question
	)
	const tagResponses = useSelector((state) => state.scoringTagResps)

	const checkedValues = questionAnswers.map((questionAnswer) => {
		return findQuestionAnswerResponse(userResponses, questionAnswer, props.question)
	})

	return (
		<div style={{ paddingBottom: 15 }}>
			<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 10 }}>{props.question.questionText}</Typography>

			<FormGroup style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
				{questionAnswers.map((questionAnswer, index) => {
					return (
						<FormControlLabel
							key={questionAnswer.answerText}
							control={
								<Checkbox
									size='small'
									color='primary'
									disableRipple
									checked={checkedValues[index]}
									onChange={(e) => {
										const newCheckedValues = [...checkedValues]
										newCheckedValues[index] = e.target.checked

										if (e.target.checked === false) {
											// Need to remove from the responses

											// Delete scoring tag response
											for (const tag of tags) {
												if (tag.data === questionAnswer.answerText) {
													deleteScoringTagResponse(tagResponses, tag, dispatch)
												}
											}

											// Delete question response
											deleteResponseByAnswerText(userResponses, props.question, questionAnswer.answerText, dispatch)
										} else {
											// Need to create a response

											// Adds the scoring tags for MatchResponse
											scoreMultipleCheckboxTags(questionAnswer.answerText, tags, tagResponses, dispatch)

											const newResponse = {
												questionID: props.question.id,
												response: questionAnswer.answerText,
												userID: props.userID,
												notes: '',
												score: questionAnswer.score, // Note that this score is still only based off of the questionAnswer
											}

											dispatch(changeUserResponses([...userResponses, newResponse]))
										}
									}}
								/>
							}
							label={<Typography style={{ fontSize: 16, textAlign: 'start' }}> {questionAnswer.answerText} </Typography>}
						/>
					)
				})}
			</FormGroup>

			{props.question.hasNotes ? <Notes question={props.question} userID={props.userID} /> : <div />}
		</div>
	)
}

export default MultipleCheckbox
