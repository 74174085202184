import React, { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Divider } from '@mui/material'
import { getRelScoringTags } from '../functions/HelperFunctions'
import { scoreMemoTags } from '../functions/ScoringTagFunctions'
import SubmitAnswerButton from '../components/Button/SubmitAnswerButton'
import { updateTimedResponse, startOrUpdateTimedResponseTime } from '../functions/UserResponseFunctions'
import { UnfinishedAttemptContext } from '../contexts/UnfinishedAttemptContext'

// TODO: Implementing a useTimedResponse hook might save a lot of work between the timed response components
// TODO: Add reducer for updating end time for timed responses

function Memo(props) {
	const dispatch = useDispatch()
	const timedUserResponses = useSelector((state) => state.timedUserResponses)
	const tags = getRelScoringTags(
		useSelector((state) => state.scoringTags),
		props.question
	)
	const tagResponses = useSelector((state) => state.scoringTagResps)

	// eslint-disable-next-line
	const existingResponse = timedUserResponses.find((response) => response.questionID === props.question.id)

	const defaultValue = existingResponse ? existingResponse.response : ''
	const [submitted, setSubmitted] = useState(existingResponse ? existingResponse.submitted : false)

	const { saveUnfinishedAttempt } = useContext(UnfinishedAttemptContext)

	// Adds a new time block to the response. If the user performs no actions, then the time block will not contribute
	// any time (start and end block are the same time)
	useEffect(() => {
		startOrUpdateTimedResponseTime(timedUserResponses, props.question, dispatch)
		// eslint-disable-next-line
	}, [])

	function submitResponse() {
		setSubmitted(true)
		updateTimedResponse({ submitted: true }, timedUserResponses, props.question, dispatch)
	}

	return (
		<div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
			<SubmitAnswerButton onSubmit={submitResponse} submitted={submitted} />

			<Divider sx={{ width: '100%', margin: '15px 0 30px' }} />
			{/*  ################### QUESTION TEXT ###################### */}
			<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 10 }}>{props.question.questionText}</Typography>
			{/*  ################### QUESTION TEXT ###################### */}

			{/* #################### RESPONSE TEXT AREA ######################## */}
			<textarea
				style={{ width: '100%', resize: 'none', paddingBottom: 15 }}
				defaultValue={defaultValue}
				onChange={() => {
					if (!submitted) return

					setSubmitted(false)
					updateTimedResponse({ submitted: false }, timedUserResponses, props.question, dispatch)
				}}
				onBlur={(e) => {
					setSubmitted(false)

					const score = scoreMemoTags(e.target.value, tags, tagResponses, dispatch)
					updateTimedResponse({ response: e.target.value, score, submitted: false }, timedUserResponses, props.question, dispatch)
					saveUnfinishedAttempt()
				}}
			/>
			{/* #################### RESPONSE TEXT AREA ######################## */}
		</div>
	)
}

export default Memo
