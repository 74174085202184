import React, { useEffect, useState } from 'react'
import { Typography, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getRelScoringTags } from '../functions/HelperFunctions'
import { scoreShortResponseTags } from '../functions/ScoringTagFunctions'
import { addSingleResponse, findDefaultValue } from '../functions/UserResponseFunctions'

import Notes from './Notes'

function ShortResponse(props) {
	const dispatch = useDispatch()
	const userResponses = useSelector((state) => state.userResponses)
	const tags = getRelScoringTags(
		useSelector((state) => state.scoringTags),
		props.question
	)
	const tagResponses = useSelector((state) => state.scoringTagResps)

	const [value, setValue] = useState('')

	// Gets the existing value if possible
	useEffect(() => {
		findDefaultValue(props.question.id, userResponses, setValue)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
			<Typography style={{ fontSize: 16, textAlign: 'start' }}>{props.question.questionText}</Typography>
			<TextField
				style={{ width: 300, paddingBottom: 15 }}
				value={value}
				label={props.question.helperText}
				autoComplete='off'
				inputProps={{ maxLength: 70 }}
				InputLabelProps={{ style: { fontSize: 16 } }}
				onChange={(e) => setValue(e.target.value)}
				onBlur={(e) => {
					const score = scoreShortResponseTags(e.target.value, tags, tagResponses, dispatch)

					const newResponse = {
						questionID: props.question.id,
						response: e.target.value,
						notes: '',
						score,
					}

					addSingleResponse(newResponse, userResponses, props.question, dispatch)
				}}
			/>
			{props.question.hasNotes ? <Notes question={props.question} userID={props.userID} /> : <div />}
		</div>
	)
}

export default ShortResponse
