export const changeFirstName = (newFirstName) => {
	return {
		type: 'CHANGEFIRSTNAME',
		payload: newFirstName,
	}
}

export const changeLastName = (newLastName) => {
	return {
		type: 'CHANGELASTNAME',
		payload: newLastName,
	}
}

export const changeAddress = (newAddress) => {
	return {
		type: 'CHANGEADDRESS',
		payload: newAddress,
	}
}

export const changeCity = (newCity) => {
	return {
		type: 'CHANGECITY',
		payload: newCity,
	}
}

export const changePostalCode = (newPostalCode) => {
	return {
		type: 'CHANGEPOSTALCODE',
		payload: newPostalCode,
	}
}

export const changeEmail = (newEmail) => {
	return {
		type: 'CHANGEEMAIL',
		payload: newEmail,
	}
}

export const changeProvince = (newProvince) => {
	return {
		type: 'CHANGEPROVINCE',
		payload: newProvince,
	}
}

export const changeID = (newID) => {
	return {
		type: 'CHANGEID',
		payload: newID,
	}
}

export const changeInitialTimeRemaining = (newTime) => {
	return {
		type: 'CHANGEINITIALTIMEREMAINING',
		payload: newTime,
	}
}

export const changeStartTime = (newTime) => {
	return {
		type: 'CHANGESTARTTIME',
		payload: newTime,
	}
}

export const changeQuestions = (newQuestions) => {
	return {
		type: 'CHANGEQUESTIONS',
		payload: newQuestions,
	}
}

export const changeQuestionAnswers = (newQuestionAnswers) => {
	return {
		type: 'CHANGEQUESTIONANSWERS',
		payload: newQuestionAnswers,
	}
}

export const changeUserResponses = (newUserResponses) => {
	return {
		type: 'CHANGEUSERRESPONSES',
		payload: newUserResponses,
	}
}

export const changeTimedUserResponses = (newUserResponses) => {
	return {
		type: 'CHANGETIMEDUSERRESPONSES',
		payload: newUserResponses,
	}
}

export const changeCaseStudyTime = (newTime) => {
	return {
		type: 'CHANGECASESTUDYTIME',
		payload: newTime,
	}
}

export const changePhoneNumber = (newNumber) => {
	return {
		type: 'CHANGEPHONENUMBER',
		payload: newNumber,
	}
}

export const changeVoiceRecord = (newVoice) => {
	return {
		type: 'CHANGEVOICERECORD',
		payload: newVoice,
	}
}

export const changeInfoButtonResponse = (newResponse) => {
	return {
		type: 'CHANGEINFOBUTTONRESPONSE',
		payload: newResponse,
	}
}

export const changeStartedCS = (newItem) => {
	return {
		type: 'CHANGESTARTEDCS',
		payload: newItem,
	}
}

export const changeCSQuestionIndex = (newItem) => {
	return {
		type: 'CHANGECSQUESTIONINDEX',
		payload: newItem,
	}
}

export const changeTestID = (newItem) => {
	return {
		type: 'CHANGETESTID',
		payload: newItem,
	}
}

export const changeAuthenticated = (newItem) => {
	return {
		type: 'CHANGEAUTHENTICATED',
		payload: newItem,
	}
}

export const changeAccessCode = (newItem) => {
	return {
		type: 'CHANGEACCESSCODE',
		payload: newItem,
	}
}

export const changeLogo = (newItem) => {
	return {
		type: 'CHANGELOGO',
		payload: newItem,
	}
}

export const changeCaseStudyFiles = (newItem) => {
	return {
		type: 'CHANGECASESTUDYFILES',
		payload: newItem,
	}
}

export const changeScoringTags = (newItem) => {
	return {
		type: 'CHANGESCORINGTAGS',
		payload: newItem,
	}
}

export const changeScoringTagResps = (newItem) => {
	return {
		type: 'CHANGESCORINGTAGRESPS',
		payload: newItem,
	}
}

export const changePageIndex = (newItem) => {
	return {
		type: 'CHANGEPAGEINDEX',
		payload: newItem,
	}
}

export const changeSelfIdentificationQuestions = (newItem) => {
	return {
		type: 'CHANGESELFIDENTIFICATIONQUESTIONS',
		payload: newItem,
	}
}

export const changePersonalAccessCode = (newItem) => {
	return {
		type: 'CHANGEPERSONALACCESSCODE',
		payload: newItem,
	}
}
