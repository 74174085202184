import React from "react";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button } from "@mui/material";
import { changeInfoButtonResponse } from "../actions";
import { useSelector, useDispatch } from "react-redux";

function PositionInfoButton(props) {

    let infoButtonResponse = useSelector(state => state.infoButtonResponse)
    const dispatch = useDispatch()

    return (
        <Button 
            disableRipple 
            onClick={() => {

                // If opening info button, increase score by 0.25
                if (props.trigger === false && props.affectScore === true) {

                    if (infoButtonResponse < 1.0) {
                        infoButtonResponse += 0.25
                        dispatch(changeInfoButtonResponse(infoButtonResponse))
                    }

                }

                props.setTrigger(!props.trigger)
            }}
        >
            <InfoOutlinedIcon 
                style={{ height: 35, width: 35, color: '#595959'}}
            />
        </Button>
    )

}

export default PositionInfoButton