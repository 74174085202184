import React, { useState } from 'react'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle'
import { Typography, Button, Collapse } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getRelScoringTags } from '../functions/HelperFunctions'
import { scoreDropdownTags } from '../functions/ScoringTagFunctions'
import { addSingleResponse } from '../functions/UserResponseFunctions'

function DropdownQuestion(props) {
	const [showDropdown, setShowDropdown] = useState(false)

	const userResponses = useSelector((state) => state.userResponses)
	const tags = getRelScoringTags(
		useSelector((state) => state.scoringTags),
		props.question
	)
	const tagResponses = useSelector((state) => state.scoringTagResps)
	const dispatch = useDispatch()

	return (
		<div style={{ display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
			<Button
				data-cy={props.header}
				onClick={() => {
					setShowDropdown(!showDropdown)

					// The user is closing the dropdown. So, don't add a new response
					if (showDropdown) return

					let score = scoreDropdownTags(tags, tagResponses, dispatch)

					const newResponse = {
						questionID: props.question.id,
						response: 'The user viewed this dropdown.',
						notes: '',
						score: score,
					}

					addSingleResponse(newResponse, userResponses, props.question, dispatch)
				}}
				style={{
					textAlign: 'center',
					paddingLeft: 0,
					fontSize: 20,
					paddingBottom: 25,
					fontWeight: 'bold',
					textTransform: 'none',
					display: 'flex',
					justifyContent: 'start',
					padding: 10,
				}}
			>
				<ArrowDropDownCircleIcon style={{ color: '#0066b2', paddingRight: 10 }} />
				{props.header}
			</Button>

			<Collapse in={showDropdown}>
				{/* {showDropdown ? (
					<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 15, paddingLeft: 15, whiteSpace: 'pre-line' }}>
						{props.question.questionText}
					</Typography>
				) : (
					<div />
				)} */}
				<Typography
					style={{
						fontSize: 16,
						textAlign: 'start',
						paddingBottom: 15,
						paddingLeft: 15,
						whiteSpace: 'pre-line',
					}}
				>
					{props.question.questionText}
				</Typography>
			</Collapse>
		</div>
	)
}

export default DropdownQuestion
