import React from 'react'

function PageContainer({ children, ...styleProps }) {
	return (
		<div
			className='page'
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				minHeight: '100vh',
				padding: '5vh 4vw',
				width: '100%',
				boxSizing: 'border-box',
				...styleProps,
			}}
		>
			{children}
		</div>
	)
}

export default PageContainer
