import React from 'react'
import { Typography, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material'

function MultipleChoice({ question, index, responses, updateResponses }) {
	const isOtherSelected = responses[question.id][0]?.startsWith('Other')

	return (
		<div>
			<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 10 }}>
				{index + 1}. {question.questionText}
			</Typography>

			<FormControl style={{ display: 'flex', justifyContent: 'start', paddingBottom: 20 }} value={responses[question.id]}>
				<RadioGroup
					onChange={(e) => {
						updateResponses(question.id, [e.target.value])
					}}
				>
					{question.questionAnswers.map((answer, index) => {
						return (
							<FormControlLabel
								key={index}
								value={answer}
								control={<Radio size='small' disableRipple color='primary' style={{ padding: '7px' }} />}
								label={
									<Typography style={{ fontSize: 14, textAlign: 'start', height: 'fit-content' }}>{answer}</Typography>
								}
							/>
						)
					})}
				</RadioGroup>

				{isOtherSelected && (
					<TextField
						style={{ marginLeft: 15 }}
						onBlur={(e) => {
							updateResponses(question.id, 'Other - ' + e.target.value)
						}}
					/>
				)}
			</FormControl>
		</div>
	)
}

export default MultipleChoice
