import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Button, Typography } from '@mui/material'
import { useState } from 'react'

import CardContainer from './Containers/CardContainer'
import { useSelector } from 'react-redux'

function HelpButton() {
	const [showHelp, setShowHelp] = useState(false)
	const personalAccessCode = useSelector((state) => state.personalAccessCode)

	return (
		<>
			<Button
				style={{}}
				onClick={() => {
					setShowHelp(!showHelp)
				}}
			>
				<HelpOutlineIcon style={{ height: 35, width: 35, color: '#595959' }} />
			</Button>
			{showHelp && (
				<div className='popup-container'>
					<CardContainer
						style={{
							width: 400,
							position: 'absolute',
							right: '8%',
							top: '50%',
							transform: 'translate(0, -50%)',
							zIndex: 10,
							alignItems: 'start',
							gap: 10,
						}}
					>
						<Button
							style={{ position: 'absolute', top: 5, right: 5 }}
							onClick={() => {
								setShowHelp(false)
							}}
						>
							<CloseOutlinedIcon style={{ height: 30, width: 30, color: '#0066b2' }} />
						</Button>

						<Typography style={{ fontSize: 22, fontWeight: 'bold', marginBottom: 10 }}>Need Help?</Typography>

						<Typography style={{ fontSize: 16, textAlign: 'start' }}>
							Please email support@btrhire.com for any technical difficulties or other issues. We will try to get back to you
							as soon as we can.
						</Typography>

						<Typography style={{ textAlign: 'start', marginTop: 10 }}>Your personal access code:</Typography>
						<Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{personalAccessCode}</Typography>
					</CardContainer>
				</div>
			)}
		</>
	)
}

export default HelpButton
