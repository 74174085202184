import React from 'react'
import styles from './Cover.module.css'
import MonitorIcon from '@mui/icons-material/Monitor'

function WarningCover() {
	return (
		<div className={styles['cover']}>
			<div className={styles['content']}>
				<MonitorIcon style={{ margin: 'auto', height: 90, width: 90, color: 'rgb(40, 40, 40)' }} />
				<p style={{ fontWeight: 'bold', textAlign: 'center' }}>Sorry, we can't fit everything on your screen!</p>{' '}
				<p>
					{' '}
					Please use a laptop or desktop computer if possible. We are currently working on a mobile version and hope to have it
					released soon. If you are already on a laptop or desktop computer, please try to make this window full screen. If you
					believe you are seeing this screen by mistake, please reach out to us at support@btrhire.com.
				</p>
			</div>
		</div>
	)
}

export default WarningCover
