import React, { useState } from 'react'
import { Typography, Box } from '@mui/material'
import DropdownQuestion from '../questions/DropdownQuestion'
import { useSelector } from 'react-redux'
import { getRelevantQuestions } from '../functions/HelperFunctions'
import PositionInfoButton from '../components/PositionInfoButton'
import PositionDetailsPopup from '../components/PositionDetailsPopup'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import HelpButton from '../components/HelpButton'

import './Pages.css'
import NavButton from '../components/Button/NavButton'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'
import PersonalAccessCodeModal from '../components/PersonalAccessCodeModal'

function PositionDetails(props) {
	const ksQuestion = getRelevantQuestions(
		useSelector((state) => state.questions),
		'KeySkills'
	)
	const atoQuestion = getRelevantQuestions(
		useSelector((state) => state.questions),
		'AboutThisOpportunity'
	)
	const overQuestion = getRelevantQuestions(
		useSelector((state) => state.questions),
		'Overview'
	)
	const respQuestion = getRelevantQuestions(
		useSelector((state) => state.questions),
		'Responsibilities'
	)
	const [showInfo, setShowInfo] = useState(false)
	const logo = useSelector((state) => state.logo)

	return (
		<>
			<PageContainer>
				<CardContainer style={{ paddingTop: 0 }}>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: 'calc(100% + 25px)',
							minHeight: 75,
						}}
					>
						<img src={logo} alt='' style={{ maxHeight: 100, maxWidth: 200, display: 'flex', justifyContent: 'start' }} />
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<PositionInfoButton setTrigger={setShowInfo} trigger={showInfo} />
							<HelpButton />
						</div>
					</div>

					<Box width='100%'>
						{atoQuestion.length > 0 ? <DropdownQuestion question={atoQuestion[0]} header='About this Opportunity' /> : <div />}
						{overQuestion.length > 0 ? <DropdownQuestion question={overQuestion[0]} header='Overview' /> : <div />}
						{respQuestion.length > 0 ? <DropdownQuestion question={respQuestion[0]} header='Responsibilities' /> : <div />}
						{ksQuestion.length > 0 ? (
							<DropdownQuestion question={ksQuestion[0]} header='Key Required Skills and Experience' />
						) : (
							<div />
						)}
					</Box>
					<div style={{ display: 'flex', alignItems: 'center', paddingTop: 10 }}>
						<Typography style={{ fontSize: 16, color: 'grey', textAlign: 'start' }}>
							Note: You may refer to this information at any time by selecting the
							<span>
								<InfoOutlinedIcon style={{ color: 'grey', paddingLeft: 2, paddingRight: 4, marginBottom: -6 }} />
							</span>
							icon at the top of your screen.
						</Typography>
						{/* <Typography style={{ fontSize: 12, color: 'grey' }}>icon at the top of your screen.</Typography> */}
					</div>
					<div className='NavigationContainer'>
						{/* <NavButton text="Previous" link="yes" path="/WelcomeForm"/>
                        <NavButton text="Continue" link="yes" path="/AboutYourself"/> */}
						<div />
						<NavButton text='Next' onClick={() => props.nextPage()} saveOnPageChange />
					</div>
				</CardContainer>
				<div style={{ display: 'flex', justifyContent: 'end' }}>
					{showInfo ? <PositionDetailsPopup setTrigger={setShowInfo} /> : <div />}
				</div>
			</PageContainer>
			<PersonalAccessCodeModal />
		</>
	)
}

export default PositionDetails
