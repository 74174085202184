import React from 'react'
import Card from '@mui/material/Card'

const CardContainer = React.forwardRef(({ children, ...props }, ref) => {
	return (
		<Card
			elevation={3}
			ref={ref}
			sx={{
				width: props.width ? props.width : 'min(600px, 90vw)',
				padding: '20px 40px 40px 40px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
			{...props}
		>
			{children}
		</Card>
	)
})

export default CardContainer
