import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getRelevantQuestionAnswers, getRelScoringTags } from '../functions/HelperFunctions'
import { scoreTimedMultipleChoiceTags } from '../functions/ScoringTagFunctions'
import { FormControl, Typography, FormControlLabel, Radio, RadioGroup, Divider } from '@mui/material'

import SubmitAnswerButton from '../components/Button/SubmitAnswerButton'
import { useEffect } from 'react'

function findQuestionAnswerScore(response, questionAnswers) {
	for (const questionAnswer of questionAnswers) {
		if (questionAnswer.answerText === response) {
			return questionAnswer.score
		}
	}

	return 0
}

function TimedMultipleChoice(props) {
	const dispatch = useDispatch()
	const questionAnswers = getRelevantQuestionAnswers(props.questionAnswers, props.question.id)
	const timedUserResponses = useSelector((state) => state.timedUserResponses)
	const tags = getRelScoringTags(
		useSelector((state) => state.scoringTags),
		props.question
	)
	const tagResponses = useSelector((state) => state.scoringTagResps)

	const existingResponse = timedUserResponses.find((response) => response.questionID === props.question.id)

	const [submitted, setSubmitted] = useState(existingResponse ? existingResponse.submitted : false)
	const [value, setValue] = useState(
		existingResponse ? { response: existingResponse.response, notes: existingResponse.notes } : { response: '', notes: '' }
	)

	// Adds a new time block to the response. If the user performs no actions, then the time block will not contribute
	// any time (start and end block are the same time)
	useEffect(() => {
		dispatch({ type: 'START_OR_UPDATE_TIME', payload: { currTime: new Date().getTime(), questionID: props.question.id } })

		// Updates end timer when user leaves question if it has not been submitted.
		return () => {
			if (!submitted) dispatch({ type: 'UPDATE_TIMED_RESPONSE', payload: { updates: {}, questionID: props.question.id } })
		}
		// eslint-disable-next-line
	}, [])

	// Fires whenever a user changes the multiple choice answer or notes answer
	function onChangeResponse(response, notes, baseResponseScore) {
		setValue({ response, notes })

		const score = scoreTimedMultipleChoiceTags(notes, tags, tagResponses, baseResponseScore, dispatch)

		dispatch({
			type: 'UPDATE_TIMED_RESPONSE',
			payload: { updates: { response, notes, score, submitted: false }, questionID: props.question.id },
		})
	}

	// Sets end time for the question and changes submitted status to true
	function onSubmit() {
		setSubmitted(true)

		dispatch({ type: 'UPDATE_TIMED_RESPONSE', payload: { updates: { submitted: true }, questionID: props.question.id } })
	}

	return (
		<div key={props.question.questionText}>
			{/* ########### QUESTION AND QUESTION ANSWERS #####################*/}
			<SubmitAnswerButton onSubmit={onSubmit} submitted={submitted} />

			<Divider sx={{ width: '100%', margin: '15px 0 30px' }} />
			<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 10 }}>{props.question.questionText}</Typography>
			<FormControl style={{ display: 'flex', justifyContent: 'start' }}>
				<RadioGroup style={{ fontSize: 40, paddingBottom: 10, display: 'flex', justifyContent: 'start' }} value={value.response}>
					{questionAnswers.map((questionAnswer, index) => {
						return (
							<FormControlLabel
								key={index}
								value={questionAnswer.answerText}
								control={<Radio size='small' disableRipple color='primary' />}
								label={<Typography style={{ fontSize: 16, textAlign: 'start' }}> {questionAnswer.answerText} </Typography>}
								onChange={(e) => {
									if (submitted) setSubmitted(false)
									onChangeResponse(e.target.value, value.notes, questionAnswer.score)
								}}
							/>
						)
					})}
				</RadioGroup>
			</FormControl>

			{/* ########### NOTES #####################*/}
			<div style={{ paddingBottom: 20 }}>
				<Typography style={{ fontSize: 16, textAlign: 'start' }}> Explain your response: </Typography>
				<textarea
					style={{ width: '100%', resize: 'none' }}
					defaultValue={value.notes}
					onChange={() => {
						if (!submitted) return

						setSubmitted(false)
						dispatch({
							type: 'UPDATE_TIMED_RESPONSE',
							payload: { updates: { submitted: false }, questionID: props.question.id },
						})
					}}
					onBlur={(e) => {
						onChangeResponse(value.response, e.target.value, findQuestionAnswerScore(value.response, questionAnswers))
					}}
				/>
			</div>
		</div>
	)
}

export default TimedMultipleChoice
