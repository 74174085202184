import React, { useState, useContext } from 'react'
import { Typography, TextField, Collapse, Button } from '@mui/material'

import BlueButton from './Button/BlueButton'
import { UnfinishedAttemptContext } from '../contexts/UnfinishedAttemptContext'

function ResumeTestInput() {
	const [isExpanded, setExpanded] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [personalAccessInput, setPersonalAccessInput] = useState('')

	const { loadUnfinishedAttempt } = useContext(UnfinishedAttemptContext)

	return (
		<>
			<hr style={{ borderColor: 'rgb(0, 0, 0, 0.3)', width: '100%', margin: '40px 0 20px' }} />
			<Typography style={{ alignSelf: 'start', cursor: 'pointer' }} onClick={() => setExpanded(true)}>
				Returning to an existing attempt? Click{' '}
				<Button
					data-cy='resume-test-button'
					variant='text'
					disableRipple
					style={{
						display: 'inline',
						textTransform: 'none',
						fontSize: 16,
						padding: 0,
						margin: 0,
						minWidth: 'unset',
						color: '#1976d2',
						lineHeight: 1.5,
						verticalAlign: 'unset',
					}}
				>
					here
				</Button>
				.
			</Typography>
			<Collapse in={isExpanded}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<TextField
						variant='outlined'
						autoComplete='off'
						error={errorMessage.length > 0}
						helperText={errorMessage.length > 0 ? errorMessage : 'Enter Personal Access Code'}
						FormHelperTextProps={{ style: { textAlign: 'center', fontSize: 16 } }}
						inputProps={{ style: { textAlign: 'center' } }}
						value={personalAccessInput}
						onChange={(e) => setPersonalAccessInput(e.target.value)}
						size='small'
						style={{ color: 'black', marginTop: 20, width: 400 }}
					/>
					<BlueButton
						style={{ marginTop: '20px', width: 'fit-content', alignSelf: 'center' }}
						onClick={async () => {
							const result = await loadUnfinishedAttempt(personalAccessInput)

							// Returns error message string. Returns empty string if successful
							if (result.length > 0) {
								setErrorMessage(result)
							}
						}}
					>
						Resume Attempt
					</BlueButton>
				</div>
			</Collapse>
		</>
	)
}

export default ResumeTestInput
