import React from 'react'
import { Button } from '@mui/material'

function SubmitAnswerButton({ onSubmit, submitted }) {
	return (
		<Button
			variant='text'
			sx={{ color: '#0066b2', textTransform: 'none', width: 150, marginTop: '15px', alignSelf: 'center' }}
			disabled={submitted}
			onClick={onSubmit}
		>
			{submitted ? 'Submitted' : 'Submit Answer'}
		</Button>
	)
}

export default SubmitAnswerButton
