import { useRef, createContext, useState, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateUnfinishedAttempt, createUnfinishedAttempt as createUnfinishedAttemptMutation } from '../graphql/mutations'
import { getUnfinishedAttempt } from '../graphql/queries'
import { API } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'

import { loadTest } from '../functions/DatabaseFunctions'
import {
	changeID,
	changePageIndex,
	changePersonalAccessCode,
	changeScoringTagResps,
	changeStartedCS,
	changeTimedUserResponses,
	changeUserResponses,
} from '../actions'
import { useEffect } from 'react'

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const UnfinishedAttemptContext = createContext()

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function formatUserResponses(userResponses, timedUserResponses) {
	const responsesToUpload = userResponses.map((userResponse) => {
		const { response, notes, questionID, score } = userResponse
		return {
			response,
			notes,
			questionID,
			score,
		}
	})

	responsesToUpload.push(
		...timedUserResponses.map((timedUserResponse) => {
			const { response, notes, questionID, score, submitted, timeTaken } = timedUserResponse

			return {
				response,
				notes,
				questionID,
				score,
				submitted,
				timeTaken,
			}
		})
	)

	return responsesToUpload
}

function UnfinishedAttemptContextWrapper(props) {
	const testID = useSelector((state) => state.testID)
	const userResponses = useSelector((state) => state.userResponses)
	const timedResponses = useSelector((state) => state.timedUserResponses)
	const scoringTagResponses = useSelector((state) => state.scoringTagResps)
	const pageIndex = useSelector((state) => state.pageIndex)
	const personalAccessCode = useSelector((state) => state.personalAccessCode)
	const voiceRecord = useSelector((state) => state.voiceRecord)
	const testTimes = useSelector((state) => state.testTimes)
	const caseStudyTimes = useSelector((state) => state.caseStudyTimes)

	const dispatch = useDispatch()

	const hasChanged = useRef(false)
	const lastExecutedTimestamp = useRef(0)

	const [recordingWarningOpen, setRecordingWarningOpen] = useState(false)
	const [hasViewedCodeModal, setHasViewedCodeModal] = useState(false)

	const handleClose = (_, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setRecordingWarningOpen(false)
	}

	useEffect(() => {
		hasChanged.current = true
	}, [userResponses, timedResponses, scoringTagResponses])

	// Decided to implement this because saving an attempt in the component will not save the updated state.
	// It will use the out of date function reference with previous timeResponses state. This way we can save the attempt
	// with the updated state, and don't have to worry about calling the function in each component. We can do this just
	// for timed user responses as the untimed responses usually take less time to complete and can save on page change.
	useEffect(() => {
		if (testID) saveUnfinishedAttempt() // Makes sure that the test has actually been loaded first

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timedResponses])

	async function saveUnfinishedAttempt() {
		if (!personalAccessCode || personalAccessCode === '') {
			console.error('No personal access code stored.')
			return
		}

		const currentTimestamp = Date.now()

		dispatch({ type: 'UPDATE_TEST_END_TIME', payload: { endTime: currentTimestamp } })

		// Calculate the time elapsed since the last execution
		const timeElapsed = currentTimestamp - lastExecutedTimestamp.current

		// If less than 5 seconds have passed since the last execution, do not save
		if (timeElapsed < 5000) {
			return
		}
		lastExecutedTimestamp.current = currentTimestamp

		console.log('saving attempt')

		const updateInput = {
			id: personalAccessCode,
			lastPage: pageIndex,
			caseStudyTimes,
			testTimes,
		}

		// Only send new response data if responses have actually changed
		if (hasChanged.current === true) {
			updateInput.responses = formatUserResponses(userResponses, timedResponses)

			updateInput.scoringTagResponses = scoringTagResponses.map((scoringTagResponse) => {
				return { scoringTagID: scoringTagResponse.scoringTagID, score: scoringTagResponse.score }
			})
		}

		if (voiceRecord && voiceRecord.length > 0) {
			updateInput.hasRecording = true
		} else {
			updateInput.hasRecording = false
		}

		try {
			await API.graphql({
				query: updateUnfinishedAttempt,
				variables: {
					input: updateInput,
				},
			})
			hasChanged.current = false
			console.log('done saving')
		} catch (e) {
			console.error(e)
		}
	}

	async function loadUnfinishedAttempt(personalAccessInput) {
		try {
			await loadTest(dispatch, testID)
			const apiResponseData = await API.graphql({ query: getUnfinishedAttempt, variables: { id: personalAccessInput } })
			const apiResponse = apiResponseData.data.getUnfinishedAttempt

			if (!apiResponse) return 'Could not find an unfinished attempt with that code.'

			if (apiResponse.testID !== testID) return 'The code you entered is for a different test.'

			const loadedUserResponses = []
			const loadedTimedUserResponses = []
			// Load in all old responses and scores
			for (const response of apiResponse.responses) {
				if (response.timeTaken) {
					loadedTimedUserResponses.push(response)
				} else {
					loadedUserResponses.push(response)
				}
			}

			if (apiResponse.caseStudyTimes && apiResponse.caseStudyTimes.length > 0) {
				dispatch(changeStartedCS(true))
			}

			setHasViewedCodeModal(true)

			// Loads userResponses and scoringTagResponses into	redux
			dispatch(changeUserResponses(loadedUserResponses))
			dispatch(changeTimedUserResponses(loadedTimedUserResponses))
			dispatch(changeScoringTagResps(apiResponse.scoringTagResponses))

			dispatch({ type: 'START_AND_LOAD_TEST_TIMES', payload: { loadedTimes: apiResponse.testTimes, newTime: new Date().getTime() } })

			dispatch(changePersonalAccessCode(personalAccessInput))
			dispatch(changeID(personalAccessInput))
			dispatch({ type: 'CHANGE_CASE_STUDY_TIMES', payload: apiResponse.caseStudyTimes })

			dispatch(changePageIndex(apiResponse.lastPage))
			if (apiResponse.hasRecording) setRecordingWarningOpen(true)
			return ''
		} catch (e) {
			console.error(e)
			return 'There was an error connecting to the database.'
		}
	}

	async function createUnfinishedAttempt() {
		try {
			const result = await API.graphql({
				query: createUnfinishedAttemptMutation,
				variables: {
					input: {
						id: uuidv4().substring(0, 11).replace('-', ''),
						testID,
						score: 0,
						responses: [],
						scoringTagResponses: [],
						lastPage: Math.round(pageIndex),
						testTimes,
						caseStudyTimes: [],
						hasRecording: false,
					},
				},
			})
			dispatch(changePersonalAccessCode(result.data.createUnfinishedAttempt.id))
			return result.data.createUnfinishedAttempt.id
		} catch (e) {
			console.error(e)
			return null
		}
	}

	return (
		<UnfinishedAttemptContext.Provider
			value={{
				saveUnfinishedAttempt,
				loadUnfinishedAttempt,
				hasViewedCodeModal,
				setHasViewedCodeModal,
				createUnfinishedAttempt,
			}}
		>
			{props.children}
			<Snackbar
				open={recordingWarningOpen}
				autoHideDuration={12000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			>
				<Alert onClose={handleClose} severity='warning' sx={{ width: 'max(50%, 500px)', textAlign: 'left' }}>
					It looks like you had a voice recording before you left. Unfortunately, we can't save the recording until you submit
					your application, so it will need to be recorded again.
				</Alert>
			</Snackbar>
		</UnfinishedAttemptContext.Provider>
	)
}

export { UnfinishedAttemptContext, UnfinishedAttemptContextWrapper }
