export const getTestForApplicant = /* GraphQL */ `
	query GetTest($id: ID!) {
		getTest(id: $id) {
			id
			testName
			logoPath
			casestudyFiles
			dataMappingTags
			clientID
			totalScore
			customScoringWeights
			selfIdentificationQuestions {
				id
				type
				questionText
				questionAnswers
			}
			createdAt
			updatedAt
		}
	}
`
