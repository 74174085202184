import React from 'react'
import { Typography, FormControl, FormControlLabel, TextField, Checkbox as MuiCheckbox } from '@mui/material'

function Checkbox({ question, index, responses, updateResponses }) {
	const isOtherSelected = responses[question.id]?.findIndex((response) => response.startsWith('Other')) >= 0

	return (
		<div>
			{/* Question Number and Text */}
			<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 10 }}>
				{index + 1}. {question.questionText}
			</Typography>

			{/* Possible answers to question */}
			<FormControl style={{ display: 'flex', justifyContent: 'start', paddingBottom: 20 }} value={responses[question.id]}>
				{question.questionAnswers.map((answer, index) => {
					return (
						<FormControlLabel
							key={index}
							control={<MuiCheckbox size='small' color='primary' disableRipple value={answer} />}
							label={<Typography style={{ fontSize: 14, textAlign: 'start' }}>{answer}</Typography>}
							onChange={(e) => {
								const value = e.target.value

								if (e.target.checked) {
									// Adds response
									if (responses[question.id]) {
										updateResponses(question.id, [...responses[question.id], value])
									} else {
										updateResponses(question.id, [value])
									}
								} else {
									// Removes response
									updateResponses(
										question.id,
										value.startsWith('Other')
											? responses[question.id].filter((item) => !item.startsWith('Other'))
											: responses[question.id].filter((item) => item !== value)
									)
								}
							}}
						/>
					)
				})}

				{isOtherSelected && (
					<TextField
						style={{ marginLeft: 15 }}
						onBlur={(e) => {
							// Removes previous 'Other' response if it exists and adds the new one
							updateResponses(question.id, [
								...responses[question.id].filter((item) => !item.startsWith('Other')),
								'Other - ' + e.target.value,
							])
						}}
					/>
				)}
			</FormControl>
		</div>
	)
}

export default Checkbox
