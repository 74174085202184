/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createScoringTag = /* GraphQL */ `
  mutation CreateScoringTag(
    $input: CreateScoringTagInput!
    $condition: ModelScoringTagConditionInput
  ) {
    createScoringTag(input: $input, condition: $condition) {
      id
      questionID
      tag
      data
      dataMapping
      maxScore
      createdAt
      updatedAt
    }
  }
`;
export const updateScoringTag = /* GraphQL */ `
  mutation UpdateScoringTag(
    $input: UpdateScoringTagInput!
    $condition: ModelScoringTagConditionInput
  ) {
    updateScoringTag(input: $input, condition: $condition) {
      id
      questionID
      tag
      data
      dataMapping
      maxScore
      createdAt
      updatedAt
    }
  }
`;
export const deleteScoringTag = /* GraphQL */ `
  mutation DeleteScoringTag(
    $input: DeleteScoringTagInput!
    $condition: ModelScoringTagConditionInput
  ) {
    deleteScoringTag(input: $input, condition: $condition) {
      id
      questionID
      tag
      data
      dataMapping
      maxScore
      createdAt
      updatedAt
    }
  }
`;
export const createScoringTagResponse = /* GraphQL */ `
  mutation CreateScoringTagResponse(
    $input: CreateScoringTagResponseInput!
    $condition: ModelScoringTagResponseConditionInput
  ) {
    createScoringTagResponse(input: $input, condition: $condition) {
      id
      scoringTagID
      userID
      score
      createdAt
      updatedAt
    }
  }
`;
export const updateScoringTagResponse = /* GraphQL */ `
  mutation UpdateScoringTagResponse(
    $input: UpdateScoringTagResponseInput!
    $condition: ModelScoringTagResponseConditionInput
  ) {
    updateScoringTagResponse(input: $input, condition: $condition) {
      id
      scoringTagID
      userID
      score
      createdAt
      updatedAt
    }
  }
`;
export const deleteScoringTagResponse = /* GraphQL */ `
  mutation DeleteScoringTagResponse(
    $input: DeleteScoringTagResponseInput!
    $condition: ModelScoringTagResponseConditionInput
  ) {
    deleteScoringTagResponse(input: $input, condition: $condition) {
      id
      scoringTagID
      userID
      score
      createdAt
      updatedAt
    }
  }
`;
export const createAccessCode = /* GraphQL */ `
  mutation CreateAccessCode(
    $input: CreateAccessCodeInput!
    $condition: ModelAccessCodeConditionInput
  ) {
    createAccessCode(input: $input, condition: $condition) {
      id
      code
      numAllowed
      timesUsed
      testID
      status
      createdBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAccessCode = /* GraphQL */ `
  mutation UpdateAccessCode(
    $input: UpdateAccessCodeInput!
    $condition: ModelAccessCodeConditionInput
  ) {
    updateAccessCode(input: $input, condition: $condition) {
      id
      code
      numAllowed
      timesUsed
      testID
      status
      createdBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccessCode = /* GraphQL */ `
  mutation DeleteAccessCode(
    $input: DeleteAccessCodeInput!
    $condition: ModelAccessCodeConditionInput
  ) {
    deleteAccessCode(input: $input, condition: $condition) {
      id
      code
      numAllowed
      timesUsed
      testID
      status
      createdBy {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionAnswer = /* GraphQL */ `
  mutation CreateQuestionAnswer(
    $input: CreateQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    createQuestionAnswer(input: $input, condition: $condition) {
      id
      questionID
      isCorrect
      answerText
      score
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionAnswer = /* GraphQL */ `
  mutation UpdateQuestionAnswer(
    $input: UpdateQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    updateQuestionAnswer(input: $input, condition: $condition) {
      id
      questionID
      isCorrect
      answerText
      score
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionAnswer = /* GraphQL */ `
  mutation DeleteQuestionAnswer(
    $input: DeleteQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    deleteQuestionAnswer(input: $input, condition: $condition) {
      id
      questionID
      isCorrect
      answerText
      score
      createdAt
      updatedAt
    }
  }
`;
export const createUserResponse = /* GraphQL */ `
  mutation CreateUserResponse(
    $input: CreateUserResponseInput!
    $condition: ModelUserResponseConditionInput
  ) {
    createUserResponse(input: $input, condition: $condition) {
      id
      response
      userID
      notes
      questionID
      score
      timeTaken
      createdAt
      updatedAt
    }
  }
`;
export const updateUserResponse = /* GraphQL */ `
  mutation UpdateUserResponse(
    $input: UpdateUserResponseInput!
    $condition: ModelUserResponseConditionInput
  ) {
    updateUserResponse(input: $input, condition: $condition) {
      id
      response
      userID
      notes
      questionID
      score
      timeTaken
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserResponse = /* GraphQL */ `
  mutation DeleteUserResponse(
    $input: DeleteUserResponseInput!
    $condition: ModelUserResponseConditionInput
  ) {
    deleteUserResponse(input: $input, condition: $condition) {
      id
      response
      userID
      notes
      questionID
      score
      timeTaken
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      format
      questionText
      section
      hasNotes
      testID
      helperText
      maxScore
      order
      referenceID
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      format
      questionText
      section
      hasNotes
      testID
      helperText
      maxScore
      order
      referenceID
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      format
      questionText
      section
      hasNotes
      testID
      helperText
      maxScore
      order
      referenceID
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      phone
      email
      firstName
      lastName
      city
      province
      postalCode
      score
      address
      caseStudyTime
      testTime
      testID
      status
      shortlisted
      codeUsed
      clientComments {
        createdBy {
          id
          name
        }
        comment
        date
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      phone
      email
      firstName
      lastName
      city
      province
      postalCode
      score
      address
      caseStudyTime
      testTime
      testID
      status
      shortlisted
      codeUsed
      clientComments {
        createdBy {
          id
          name
        }
        comment
        date
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      phone
      email
      firstName
      lastName
      city
      province
      postalCode
      score
      address
      caseStudyTime
      testTime
      testID
      status
      shortlisted
      codeUsed
      clientComments {
        createdBy {
          id
          name
        }
        comment
        date
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTest = /* GraphQL */ `
  mutation CreateTest(
    $input: CreateTestInput!
    $condition: ModelTestConditionInput
  ) {
    createTest(input: $input, condition: $condition) {
      id
      testName
      logoPath
      casestudyFiles
      dataMappingTags
      clientID
      totalScore
      customScoringWeights
      selfIdentificationQuestions {
        id
        type
        questionText
        questionAnswers
      }
      selfIdentificationResponses {
        items {
          id
          testID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTest = /* GraphQL */ `
  mutation UpdateTest(
    $input: UpdateTestInput!
    $condition: ModelTestConditionInput
  ) {
    updateTest(input: $input, condition: $condition) {
      id
      testName
      logoPath
      casestudyFiles
      dataMappingTags
      clientID
      totalScore
      customScoringWeights
      selfIdentificationQuestions {
        id
        type
        questionText
        questionAnswers
      }
      selfIdentificationResponses {
        items {
          id
          testID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTest = /* GraphQL */ `
  mutation DeleteTest(
    $input: DeleteTestInput!
    $condition: ModelTestConditionInput
  ) {
    deleteTest(input: $input, condition: $condition) {
      id
      testName
      logoPath
      casestudyFiles
      dataMappingTags
      clientID
      totalScore
      customScoringWeights
      selfIdentificationQuestions {
        id
        type
        questionText
        questionAnswers
      }
      selfIdentificationResponses {
        items {
          id
          testID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      name
      role
      userList
      testList
      clientList
      codeList
      remainingCodeUses
      createdAt
      updatedAt
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      name
      role
      userList
      testList
      clientList
      codeList
      remainingCodeUses
      createdAt
      updatedAt
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      name
      role
      userList
      testList
      clientList
      codeList
      remainingCodeUses
      createdAt
      updatedAt
    }
  }
`;
export const createErrorLog = /* GraphQL */ `
  mutation CreateErrorLog(
    $input: CreateErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    createErrorLog(input: $input, condition: $condition) {
      id
      userID
      testID
      table
      message
      createdAt
      updatedAt
    }
  }
`;
export const updateErrorLog = /* GraphQL */ `
  mutation UpdateErrorLog(
    $input: UpdateErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    updateErrorLog(input: $input, condition: $condition) {
      id
      userID
      testID
      table
      message
      createdAt
      updatedAt
    }
  }
`;
export const deleteErrorLog = /* GraphQL */ `
  mutation DeleteErrorLog(
    $input: DeleteErrorLogInput!
    $condition: ModelErrorLogConditionInput
  ) {
    deleteErrorLog(input: $input, condition: $condition) {
      id
      userID
      testID
      table
      message
      createdAt
      updatedAt
    }
  }
`;
export const createSelfIdentificationResponse = /* GraphQL */ `
  mutation CreateSelfIdentificationResponse(
    $input: CreateSelfIdentificationResponseInput!
    $condition: ModelSelfIdentificationResponseConditionInput
  ) {
    createSelfIdentificationResponse(input: $input, condition: $condition) {
      id
      testID
      responses {
        questionID
        responses
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSelfIdentificationResponse = /* GraphQL */ `
  mutation UpdateSelfIdentificationResponse(
    $input: UpdateSelfIdentificationResponseInput!
    $condition: ModelSelfIdentificationResponseConditionInput
  ) {
    updateSelfIdentificationResponse(input: $input, condition: $condition) {
      id
      testID
      responses {
        questionID
        responses
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSelfIdentificationResponse = /* GraphQL */ `
  mutation DeleteSelfIdentificationResponse(
    $input: DeleteSelfIdentificationResponseInput!
    $condition: ModelSelfIdentificationResponseConditionInput
  ) {
    deleteSelfIdentificationResponse(input: $input, condition: $condition) {
      id
      testID
      responses {
        questionID
        responses
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUnfinishedAttempt = /* GraphQL */ `
  mutation CreateUnfinishedAttempt(
    $input: CreateUnfinishedAttemptInput!
    $condition: ModelUnfinishedAttemptConditionInput
  ) {
    createUnfinishedAttempt(input: $input, condition: $condition) {
      id
      testID
      score
      responses {
        response
        notes
        questionID
        score
        timeTaken {
          startTime
          endTime
        }
        submitted
      }
      scoringTagResponses {
        scoringTagID
        score
      }
      lastPage
      testTimes {
        startTime
        endTime
      }
      caseStudyTimes {
        startTime
        endTime
      }
      hasRecording
      createdAt
      updatedAt
    }
  }
`;
export const updateUnfinishedAttempt = /* GraphQL */ `
  mutation UpdateUnfinishedAttempt(
    $input: UpdateUnfinishedAttemptInput!
    $condition: ModelUnfinishedAttemptConditionInput
  ) {
    updateUnfinishedAttempt(input: $input, condition: $condition) {
      id
      testID
      score
      responses {
        response
        notes
        questionID
        score
        timeTaken {
          startTime
          endTime
        }
        submitted
      }
      scoringTagResponses {
        scoringTagID
        score
      }
      lastPage
      testTimes {
        startTime
        endTime
      }
      caseStudyTimes {
        startTime
        endTime
      }
      hasRecording
      createdAt
      updatedAt
    }
  }
`;
export const deleteUnfinishedAttempt = /* GraphQL */ `
  mutation DeleteUnfinishedAttempt(
    $input: DeleteUnfinishedAttemptInput!
    $condition: ModelUnfinishedAttemptConditionInput
  ) {
    deleteUnfinishedAttempt(input: $input, condition: $condition) {
      id
      testID
      score
      responses {
        response
        notes
        questionID
        score
        timeTaken {
          startTime
          endTime
        }
        submitted
      }
      scoringTagResponses {
        scoringTagID
        score
      }
      lastPage
      testTimes {
        startTime
        endTime
      }
      caseStudyTimes {
        startTime
        endTime
      }
      hasRecording
      createdAt
      updatedAt
    }
  }
`;
