// Calculates the total time taken for a question by adding up all the time blocks
export function calculateTotalTimeFromTimeBlocks(timeBlocks) {
	let totalTime = 0

	if (!timeBlocks) return 0

	for (const timeBlock of timeBlocks) {
		totalTime += timeBlock.endTime - timeBlock.startTime
	}

	return totalTime
}
