import React, { useState } from 'react'
import { Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { API } from 'aws-amplify'
import { createSelfIdentificationResponse } from '../graphql/mutations'
import NavButton from '../components/Button/NavButton'
import MultipleChoice from '../questions/SelfIdentification/MultipleChoice'
import Checkbox from '../questions/SelfIdentification/Checkbox'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'

function SelfIdentification(props) {
	const questions = useSelector((state) => state.selfIdentificationQuestions)
	const testID = useSelector((state) => state.testID)
	const logo = useSelector((state) => state.logo)

	// Initiate an object that stores responses to each question in an array, stored by questionID
	// We check to make sure the questions could be found. If not, an error page will be returned.
	const [responses, setResponses] = useState(
		questions && questions.length
			? questions.reduce((obj, question) => {
					obj[question.id] = []
					return obj
			  }, {})
			: {}
	)

	if (!questions || !questions.length) {
		return <div></div>
	}

	async function submitResponses() {
		try {
			const responsesArray = []

			for (const key in responses) {
				responsesArray.push({
					questionID: key,
					responses: responses[key],
				})
			}

			await API.graphql({
				query: createSelfIdentificationResponse,
				variables: { input: { testID, responses: responsesArray } },
			})
		} catch (e) {
			console.error('Error uploading anonymous response', e)
		}
	}

	function updateMultipleChoiceResponse(questionID, newResponse) {
		const newResponses = { ...responses }
		newResponses[questionID] = newResponse
		setResponses(newResponses)
	}

	return (
		<PageContainer>
			<CardContainer style={{ paddingTop: 0, alignItems: 'start' }}>
				<div style={{ minHeight: 75, display: 'flex', alignItems: 'center' }}>
					<img src={logo} alt='' style={{ maxWidth: 200, maxHeight: 100, display: 'flex', justifyContent: 'start' }} />
				</div>
				<Typography style={{ fontSize: 20, textAlign: 'start', padding: '20px 0 5px 0', fontWeight: 'bold' }}>
					Self Identification Form
				</Typography>
				<Typography style={{ fontSize: 16, textAlign: 'start', paddingBottom: 30 }}>
					Please select any of the following options which best describes your identity.
				</Typography>

				{questions.map((question, index) => {
					switch (question.type) {
						case 'MultipleChoice':
							return (
								<MultipleChoice
									key={index}
									question={question}
									index={index}
									responses={responses}
									updateResponses={updateMultipleChoiceResponse}
								/>
							)
						case 'Checkbox':
							return (
								<Checkbox
									key={index}
									question={question}
									index={index}
									responses={responses}
									updateResponses={updateMultipleChoiceResponse}
								/>
							)
						default:
							return <div />
					}
				})}

				<div className='NavigationContainer'>
					<NavButton onClick={() => props.prevPage()} text='Cancel' />
					<NavButton
						onClick={() => {
							submitResponses()
							props.nextPage()
						}}
						text='Submit'
					/>
				</div>
			</CardContainer>
		</PageContainer>
	)
}

export default SelfIdentification
