import { Button } from '@mui/material'
import React from 'react'

function BlueButton({ children, ...props }) {
	return (
		<Button
			variant='contained'
			sx={{
				background: '#0066b2',
				textTransform: 'none',
				color: 'white',
				padding: '8px 12px',
				fontSize: 16,
				'&:hover': { opacity: 0.85 },
				'&:disabled': { background: '#0066b2', opacity: 0.5, color: 'white' },
			}}
			{...props}
		>
			{children}
		</Button>
	)
}

export default BlueButton
