import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import NavButton from '../components/Button/NavButton'
import { getRelevantQuestions } from '../functions/HelperFunctions'
import MultipleChoice from '../questions/MultipleChoice'
import ShortResponse from '../questions/ShortResponse'
import MultipleCheckbox from '../questions/MultipleCheckbox'
import LongResponse from '../questions/LongResponse'
import PositionInfoButton from '../components/PositionInfoButton'
import PositionDetailsPopup from '../components/PositionDetailsPopup'

import HelpButton from '../components/HelpButton'

import './Pages.css'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'
import PageTitle from '../components/Heading/PageTitle'

function KnowledgeAssessment(props) {
	const questions = getRelevantQuestions(
		useSelector((state) => state.questions),
		'KnowledgeAssessment'
	)
	const questionAnswers = useSelector((state) => state.questionAnswers)
	const userID = useSelector((state) => state.id)
	const [showInfo, setShowInfo] = useState(false)
	const logo = useSelector((state) => state.logo)

	return (
		<PageContainer>
			<CardContainer style={{ paddingTop: 0 }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: 'calc(100% + 25px)',
						minHeight: 75,
					}}
				>
					<img src={logo} alt='' style={{ maxHeight: 100, maxWidth: 200, display: 'flex', justifyContent: 'start' }} />
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<PositionInfoButton setTrigger={setShowInfo} trigger={showInfo} />
						<HelpButton />
					</div>
				</div>

				{/* <Typography style={{ fontSize: 20, paddingBottom: 20, fontWeight: 'bold', margin: '0 auto 0 0' }}>
					General Knowledge Assessment
				</Typography> */}
				<PageTitle>General Knowledge Assessment</PageTitle>

				<div className='question-list'>
					{questions.map((question, index) => {
						if (question.format === 'MultipleChoice') {
							return (
								<MultipleChoice
									question={question}
									questionAnswers={questionAnswers}
									userID={userID}
									key={question.id}
									questionNumber={index + 1}
								/>
							)
						} else if (question.format === 'ShortResponse') {
							return <ShortResponse question={question} userID={userID} key={question.id} />
						} else if (question.format === 'Checkbox') {
							return (
								<MultipleCheckbox question={question} questionAnswers={questionAnswers} userID={userID} key={question.id} />
							)
						} else if (question.format === 'LongResponse') {
							return <LongResponse question={question} userID={userID} key={question.id} />
						} else {
							return <div />
						}
					})}
				</div>
				<div className='NavigationContainer'>
					<NavButton text='Back' onClick={() => props.prevPage()} saveOnPageChange />
					<NavButton text='Next' onClick={() => props.nextPage()} saveOnPageChange />
				</div>
			</CardContainer>

			<div style={{ display: 'flex', justifyContent: 'end' }}>
				{showInfo ? <PositionDetailsPopup setTrigger={setShowInfo} /> : <div />}
			</div>
		</PageContainer>
	)
}

export default KnowledgeAssessment
