/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://ar6ridgz55gtdmlxs6dtl4757m.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-qe5k5lsbkrhtxodwrx66oikhu4",
    "aws_cognito_identity_pool_id": "us-east-2:3e7b4f42-0d15-4bb9-8257-23f0b1952072",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_ljXQGG0cA",
    "aws_user_pools_web_client_id": "5l37evobi9ml9fuqq76tjpmdkr",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "btr-hire-files164735-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
