import React, { useState } from 'react'
import NavButton from '../components/Button/NavButton'
import { Typography, TextField, Snackbar, Alert } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import {
	deleteUnfinishedAttempt,
	pushResponsesToDatabase,
	pushScoringTagResps,
	pushTimedResponsesToDatabase,
	submitUser,
	updateAccessCodeAttempts,
	uploadVoiceRecording,
} from '../functions/DatabaseFunctions'
import {
	changeFirstName,
	changeLastName,
	changeAddress,
	changeCity,
	changePostalCode,
	changeEmail,
	changeProvince,
	changePhoneNumber,
} from '../actions'
import PageContainer from '../components/Containers/PageContainer'
import CardContainer from '../components/Containers/CardContainer'

const helperTextStyle = { fontSize: 14 }

function ConfirmEmail(props) {
	const userResponses = useSelector((state) => state.userResponses)
	const timedUserResponses = useSelector((state) => state.timedUserResponses)
	const infoButtonResponse = useSelector((state) => state.infoButtonResponse)
	const [loading, setLoading] = useState(false)
	const [hasSubmissionError, setHasSubmissionError] = useState(false)

	const inputStyle = { WebkitBoxShadow: '0 0 0 1000px white inset' } // This affects how the fields look when autofilled

	const [firstNameError, setFirstNameError] = useState(false)
	const [lastNameError, setLastNameError] = useState(false)
	const [emailError, setEmailError] = useState(false)
	const [cityError, setCityError] = useState(false)
	const [addressError, setAddressError] = useState(false)
	const [phoneError, setPhoneError] = useState(false)
	const [provinceError, setProvinceError] = useState(false)
	const [postalError, setPostalError] = useState(false)

	const firstName = useSelector((state) => state.firstName)
	const lastName = useSelector((state) => state.lastName)
	const address = useSelector((state) => state.address)
	const city = useSelector((state) => state.city)
	const postalCode = useSelector((state) => state.postalCode)
	const email = useSelector((state) => state.email)
	const province = useSelector((state) => state.province)
	const phoneNumber = useSelector((state) => state.phoneNumber)
	const testTimes = useSelector((state) => state.testTimes)
	const voiceRecord = useSelector((state) => state.voiceRecord)
	const testID = useSelector((state) => state.testID)
	const code = useSelector((state) => state.accessCode)
	const tagResponses = useSelector((state) => state.scoringTagResps)
	const logo = useSelector((state) => state.logo)
	const userID = useSelector((state) => state.id)
	const caseStudyTimes = useSelector((state) => state.caseStudyTimes)
	const personalAccessCode = useSelector((state) => state.personalAccessCode)
	const dispatch = useDispatch()

	const confirm = async () => {
		if (firstName === 'TestPass1') {
			props.nextPage()
			return
		}

		let isError = false
		setLoading(true)

		if (firstName === '') {
			setFirstNameError(true)
			isError = true
		} else {
			setFirstNameError(false)
		}

		if (lastName === '') {
			setLastNameError(true)
			isError = true
		} else {
			setLastNameError(false)
		}

		if (postalCode === '') {
			setPostalError(true)
			isError = true
		} else {
			setPostalError(false)
		}

		if (province === '') {
			setProvinceError(true)
			isError = true
		} else {
			setProvinceError(false)
		}

		if (email === '') {
			setEmailError(true)
			isError = true
		} else {
			setEmailError(false)
		}

		if (address === '') {
			setAddressError(true)
			isError = true
		} else {
			setAddressError(false)
		}

		if (city === '') {
			setCityError(true)
			isError = true
		} else {
			setCityError(false)
		}

		if (phoneNumber === '') {
			setPhoneError(true)
			isError = true
		} else {
			setPhoneError(false)
		}

		if (isError) {
			setLoading(false)
			return
		}

		const userResult = await submitUser(
			userID,
			firstName,
			lastName,
			email,
			phoneNumber,
			address,
			city,
			province,
			postalCode,
			testTimes,
			caseStudyTimes,
			userResponses,
			timedUserResponses,
			infoButtonResponse,
			testID,
			code
		)

		if (userResult) {
			console.log('Successfully submitted user.')
		} else {
			setLoading(false)
			setHasSubmissionError(true)
			return
		}

		const responsesResult = await pushResponsesToDatabase(userResponses, userID, testID)

		if (responsesResult) {
			console.log('Successfully uploaded untimed responses.')
		} else {
			setLoading(false)
			setHasSubmissionError(true)
			return
		}

		const timedResponsesResult = await pushTimedResponsesToDatabase(timedUserResponses, userID, testID)

		if (timedResponsesResult) {
			console.log('Successfully uploaded timed responses.')
		} else {
			setLoading(false)
			setHasSubmissionError(true)
			return
		}

		const tagResult = await pushScoringTagResps(tagResponses, userID, testID)

		if (tagResult) {
			console.log('Successfully uploaded scoring tag responses.')
		} else {
			setLoading(false)
			setHasSubmissionError(true)
			return
		}

		const voiceResult = await uploadVoiceRecording(voiceRecord, userID)
		if (voiceResult) {
			console.log('Successfully uploaded voice recording.')
		}

		try {
			await updateAccessCodeAttempts(code)
			console.log('Successfully updated access code attempts.')
		} catch (e) {
			console.error('Error updating access code attempts.')
		}

		sessionStorage.clear()

		deleteUnfinishedAttempt(personalAccessCode)
		props.nextPage()
	}

	return (
		<PageContainer>
			<CardContainer style={{ width: 450, paddingTop: 0 }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', minHeight: 100 }}>
					<img src={logo} alt='' style={{ maxHeight: 100, maxWidth: 200, display: 'flex', justifyContent: 'start' }} />
				</div>

				<Typography style={{ fontSize: 16, textAlign: 'start', marginBottom: 20 }}>
					Please review your contact information and click Next to submit your application.
				</Typography>

				<div style={{ display: 'flex', justifyContent: 'start' }}>
					<TextField
						error={firstNameError}
						style={{ padding: 10, width: 200 }}
						helperText={firstNameError ? 'First name is required.' : 'First Name*'}
						onChange={(e) => dispatch(changeFirstName(e.target.value))}
						value={firstName}
						inputProps={{ style: inputStyle }}
						FormHelperTextProps={{ style: helperTextStyle }}
						data-cy='firstname-input'
					/>

					<TextField
						style={{ padding: 10, width: 200 }}
						error={lastNameError}
						helperText={lastNameError ? 'Last name is required.' : 'Last Name*'}
						value={lastName}
						onChange={(e) => dispatch(changeLastName(e.target.value))}
						inputProps={{ style: inputStyle }}
						FormHelperTextProps={{ style: helperTextStyle }}
						data-cy='lastname-input'
					/>
				</div>

				<div style={{ display: 'flex', justifyContent: 'start' }}>
					<TextField
						style={{ padding: 10, width: 200 }}
						error={addressError}
						helperText={addressError ? 'Address is required.' : 'Address*'}
						value={address}
						onChange={(e) => dispatch(changeAddress(e.target.value))}
						inputProps={{ style: inputStyle }}
						FormHelperTextProps={{ style: helperTextStyle }}
						data-cy='address-input'
					/>

					<TextField
						style={{ padding: 10, width: 200 }}
						error={cityError}
						helperText={cityError ? 'City is required.' : 'City*'}
						value={city}
						onChange={(e) => dispatch(changeCity(e.target.value))}
						inputProps={{ style: inputStyle }}
						FormHelperTextProps={{ style: helperTextStyle }}
						data-cy='city-input'
					/>
				</div>

				<div style={{ display: 'flex', justifyContent: 'start' }}>
					<TextField
						style={{ padding: 10, width: 200 }}
						value={province}
						error={provinceError}
						helperText={provinceError ? 'Province is required.' : 'Province'}
						onChange={(e) => dispatch(changeProvince(e.target.value))}
						inputProps={{ style: inputStyle, maxLength: 30 }}
						FormHelperTextProps={{ style: helperTextStyle }}
						data-cy='province-input'
					/>

					<TextField
						style={{ padding: 10, width: 200 }}
						helperText='Postal Code'
						error={postalError}
						value={postalCode}
						onChange={(e) => dispatch(changePostalCode(e.target.value))}
						inputProps={{ style: inputStyle }}
						FormHelperTextProps={{ style: helperTextStyle }}
						data-cy='postalcode-input'
					/>
				</div>

				<div style={{ display: 'flex', justifyContent: 'start' }}>
					<TextField
						style={{ padding: 10, width: 420 }}
						error={emailError}
						helperText={emailError ? 'Email is required.' : 'Email*'}
						value={email}
						onChange={(e) => dispatch(changeEmail(e.target.value))}
						inputProps={{ style: inputStyle }}
						FormHelperTextProps={{ style: helperTextStyle }}
						data-cy='email-input'
					/>
				</div>

				<div style={{ display: 'flex', justifyContent: 'start' }}>
					<TextField
						style={{ padding: 10, width: 420 }}
						error={phoneError}
						helperText={phoneError ? 'Phone number is required.' : 'Phone Number*'}
						value={phoneNumber}
						onChange={(e) => dispatch(changePhoneNumber(e.target.value))}
						inputProps={{ style: inputStyle }}
						FormHelperTextProps={{ style: helperTextStyle }}
						data-cy='phone-input'
					/>
				</div>
				<div className='NavigationContainer'>
					<NavButton text='Back' onClick={() => props.prevPage()} disabled={loading || hasSubmissionError} />
					<NavButton text='Next' onClick={confirm} loading={loading} disabled={hasSubmissionError} />
				</div>
				<Snackbar open={hasSubmissionError} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
					<Alert variant='filled' severity='error' style={{ textAlign: 'left', width: 600 }}>
						Unfortunately, there was an error uploading your submission. Your progress has been saved and someone at BTR should
						be able to help you get your responses submitted. Please email us at support@btrhire.com.
					</Alert>
				</Snackbar>
			</CardContainer>
		</PageContainer>
	)
}

export default ConfirmEmail
