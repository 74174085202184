import React from 'react'
import { getRelevantQuestions } from '../functions/HelperFunctions'
import { useSelector } from 'react-redux'
import DropdownQuestion from '../questions/DropdownQuestion'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Button, Card } from '@mui/material'

function PositionDetailsPopup(props) {
	const ksQuestion = getRelevantQuestions(
		useSelector((state) => state.questions),
		'KeySkills'
	)
	const atoQuestion = getRelevantQuestions(
		useSelector((state) => state.questions),
		'AboutThisOpportunity'
	)
	const overQuestion = getRelevantQuestions(
		useSelector((state) => state.questions),
		'Overview'
	)
	const respQuestion = getRelevantQuestions(
		useSelector((state) => state.questions),
		'Responsibilities'
	)

	return (
		<div className='popup-container'>
			<Card
				style={{
					position: 'fixed',
					width: '500px',
					minHeight: 300,
					right: '1%',
					bottom: '30%',
					background: 'white',
				}}
			>
				<div style={{ margin: '5px 5px', display: 'flex', justifyContent: 'end' }}>
					<Button onClick={() => props.setTrigger(false)}>
						<CloseOutlinedIcon style={{ height: 30, width: 30, color: '#0066b2' }} />
					</Button>
				</div>

				<div style={{ paddingLeft: 20, width: '95%', maxHeight: 'min(500px, 60vh)', overflowY: 'auto' }}>
					{atoQuestion.length > 0 ? <DropdownQuestion question={atoQuestion[0]} header='About this Opportunity' /> : <div />}
					{overQuestion.length > 0 ? <DropdownQuestion question={overQuestion[0]} header='Overview' /> : <div />}
					{respQuestion.length > 0 ? <DropdownQuestion question={respQuestion[0]} header='Responsibilities' /> : <div />}
					{ksQuestion.length > 0 ? (
						<DropdownQuestion question={ksQuestion[0]} header='Key Required Skills and Experience' />
					) : (
						<div />
					)}
				</div>
			</Card>
		</div>
	)
}

export default PositionDetailsPopup
